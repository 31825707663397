import { useEffect } from "react";
import { useLazyDownloadQuery } from "src/shared/store/api/attachments.api";
import { IUploadedFile } from "./types";
import { UploadedFileContainer } from "./styles";

export const UploadedFile = ({
  containerName,
  file,
  folder,
}: IUploadedFile) => {
  const [downloadFileByName, { data: downloadedFile }] = useLazyDownloadQuery(
    {}
  );

  useEffect(() => {
    if (!downloadedFile) {
      downloadFileByName({ containerName, folder, fileName: file });
    }
  }, [containerName, folder, file, downloadFileByName, downloadedFile]);

  const handleDownload = () => {
    const url = window.URL || window.webkitURL;
    const blob = url.createObjectURL(downloadedFile);

    const hiddenElement = document.createElement("a");
    hiddenElement.href = blob;
    hiddenElement.target = "_blank";
    hiddenElement.download = file;
    hiddenElement.click();
  };

  return (
    <UploadedFileContainer onClick={handleDownload}>
      {file}
    </UploadedFileContainer>
  );
};
