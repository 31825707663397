export const INSPECTIONS_NOTIFICATIONS_GRID_NAME = "inspections-notifications";

export const rowGroupPanelShow = "always";

export const defaultColDef = {
  resizable: true,
};

const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnDefs = (t: (value: string) => string) => [
  {
    cellRenderer: "agGroupCellRenderer",
    field: "",
    width: 50,
    suppressMovable: true,
    resizable: false,
    suppressMenu: true,
  },
  {
    headerName: t("inspection-title"),
    field: "title",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("account"),
    field: "accountName",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("inspection-type"),
    field: "storageType",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("schedule"),
    field: "schedule",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
    hide: true,
  },
];

export const INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS = {
  SELECTED_INSPECTIONS_NOTIFICATIONS_ROW:
    "SELECTED_INSPECTIONS_NOTIFICATIONS_ROW",
  SELECTED_INSPECTIONS_NOTIFICATIONS: "selected_inspections-notifications",
  SCROLL_ROW: "SELECTED_INSPECTIONS_NOTIFICATIONS_SCROLL_ROW",
};
