import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import {
  ColDef,
  ColumnState,
  GridApi,
  ValueFormatterParams,
} from "ag-grid-community";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { useLazyAllContainerSearchQuery } from "src/shared/store/api/inventory.api";
import { getFromLocalStorage } from "src/shared/helpers/localStorage";
import { Compliance } from "../compliance/compliance";
import { CustomInventoryFilters } from "./customInventoryFilters/customInventoryFilters";
import {
  getColumnDefs,
  INVENTORY_GRID_NAME,
  defaultColDef,
  rowGroupPanelShow,
  DetailCellRenderer,
  excelMode,
} from "./constants";
import { ComplianceContainer } from "./styles";

export const InventoryTable = () => {
  const { t } = useTranslation();
  const { clearFromLocalStorageInventoryFilters } = useAppActions();
  const inventoryTableRef = useRef<HTMLDivElement>(null);
  const { isAfterClickOnInventoryChart } = useAppSelector(
    (state) => state.inventoryFiltersReducer
  );
  const { setIsAfterClickOnInventoryChart } = useAppActions();

  const [
    getInventoryData,
    { data: inventoryData, isFetching: isFetchingInventoryData },
  ] = useLazyAllContainerSearchQuery();

  const customInventoryFilters = (gridApi: GridApi | null) => (
    <CustomInventoryFilters
      gridApi={gridApi}
      getInventoryData={getInventoryData}
    />
  );

  const detailCellRenderer = useMemo(() => {
    return DetailCellRenderer;
  }, []);

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs(getColumnDefs(t));
  }, [t]);

  useEffect(() => {
    return () => {
      clearFromLocalStorageInventoryFilters();
    };
  }, [clearFromLocalStorageInventoryFilters]);

  useEffect(() => {
    if (isAfterClickOnInventoryChart && columnDefs?.length) {
      setIsAfterClickOnInventoryChart(false);

      if (inventoryTableRef && inventoryTableRef.current) {
        inventoryTableRef.current.scrollIntoView();
      }

      const columnState =
        getFromLocalStorage(`${INVENTORY_GRID_NAME}_column`) || null;

      const newColumnDefs = [...columnDefs];

      if (columnState) {
        columnState.forEach(({ colId, hide }: ColumnState) => {
          const colIdIndex = newColumnDefs.findIndex(
            ({ field }: ColDef) => field === colId
          );

          if (colIdIndex) {
            newColumnDefs[colIdIndex] = {
              ...newColumnDefs[colIdIndex],
              hide: hide as boolean,
            };
          }
        });
      }

      const complianceIndex = newColumnDefs.findIndex(
        ({ field }) => field === "complianceColor"
      );

      newColumnDefs[complianceIndex] = {
        headerName: t("compliance"),
        field: "complianceColor",
        cellRenderer: (valueFormatterParams: ValueFormatterParams) => {
          return (
            <ComplianceContainer>
              <Compliance color={valueFormatterParams?.value} />
            </ComplianceContainer>
          );
        },
        ...excelMode,
        hide: false,
        enableRowGroup: true,
        rowGroup: true,
      };

      setColumnDefs(newColumnDefs);
    }
  }, [
    isAfterClickOnInventoryChart,
    columnDefs,
    setIsAfterClickOnInventoryChart,
    t,
  ]);

  return (
    <div ref={inventoryTableRef}>
      <AgGrid
        title={t("list-of-inventory")}
        customFilters={customInventoryFilters}
        gridName={INVENTORY_GRID_NAME}
        rowData={inventoryData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        noRowsText={t("no-inventory-display")}
        isLoadingData={isFetchingInventoryData}
        enableRangeSelection={true}
        rowGroupPanelShow={rowGroupPanelShow}
        detailCellRenderer={detailCellRenderer}
        masterDetail={true}
        detailRowAutoHeight={true}
      />
    </div>
  );
};
