import BlockUi from "@availity/block-ui";
import {
  Box,
  Typography,
  styled,
  Radio as MuiRadio,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";

export const ScheduledReportConfigurationContainer = styled(BlockUi)(
  ({ theme }) => ({
    display: "flex",
    gap: 24,
    flexDirection: "column",
    marginTop: 24,
    borderRadius: 16,
    padding: "16px 24px",
    border: isDarkMode(theme.palette.mode)
      ? "1px solid #B1B1B1"
      : "1px solid #D9D9D9",
    [theme.breakpoints.down("md")]: {
      gap: 16,
    },
  })
);

export const ScheduleReportConfigurationFieldWrapper = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    gap: 16,
    alignItems: "center",
    "& > label": {
      textWrap: "nowrap",
    },
    "& > div": {
      display: "flex",
      maxWidth: 400,
      width: "100%",
      [theme.breakpoints.down("md")]: {
        maxWidth: "calc(100% - 124px)",
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  })
);

export const ScheduleReportConfigurationLabel = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: 16,
    minWidth: 120,
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0em",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
      minWidth: 100,
    },
  })
);

export const Radio = styled(MuiRadio)(() => ({
  color: "#DDD",
  "&.Mui-checked": {
    color: "#217BDB",
  },
  "& > span > svg": {
    width: 16,
    height: 16,
  },
  "& > input": {
    backgroundColor: "#FFF",
  },
}));

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  width: "fit-content",
  "& > span": {
    color: theme.palette.secondary.main,
    width: "fit-content",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
}));

export const RadioGroupWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  alignItems: "center",
  justifyContent: "space-between",
  "& > div": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "start",
    height: "auto !important",
  },
}));

export const DailyRadioGroupWrapper = styled(Box)(() => ({
  "& > div": {
    display: "flex",
    width: "100%",
  },
}));

export const CheckboxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
  marginLeft: 24,
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    marginLeft: 12,
    flexWrap: "wrap",
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
}));

export const InputWrapper = styled(Box)(() => ({
  width: "50px !important",
}));

export const FrequencyContainer = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  flexDirection: "column",
}));

export const FollowingDaysContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: "flex",
  gap: 16,
  marginTop: 24,
  justifyContent: "flex-end",
  "& > button": {
    minWidth: 80,
    width: "fit-content",
  },
}));

export const SelectWrapper = styled(Box)(() => ({
  "& > div": {
    maxWidth: 100,
  },
}));

export const ScheduleReportFieldText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  display: "flex",
  alignItems: "center",
  gap: 16,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "140%",
  letterSpacing: "0em",
  [theme.breakpoints.down("md")]: {
    fontSize: 12,
  },
}));

export const ReportParametersWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 24,
  flexDirection: "column",
  marginTop: 24,
  borderRadius: 16,
  padding: "16px 24px",
  border: isDarkMode(theme.palette.mode)
    ? "1px solid #B1B1B1"
    : "1px solid #D9D9D9",
  [theme.breakpoints.down("md")]: {
    gap: 16,
  },
}));

export const WeekDaysPickerWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  color: "#E35D2B",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginTop: 16,
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
    marginTop: 8,
  },
}));
