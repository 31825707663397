import {
  IDetailCellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { getSortedByMonthFormat } from "src/components/manifestTable/constants";
import { Compliance } from "src/components/compliance/compliance";
import { InventoryInformation } from "./inventoryInformation/inventoryInformation";
import { ComplianceContainer } from "./styles";

export const INVENTORY_GRID_NAME = "inventory";

export const rowGroupPanelShow = "always";

export const defaultColDef = {
  resizable: true,
};

export const getLocaleDateValue = (date?: string) => {
  return date ? new Date(date).toLocaleDateString() : "";
};

export const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnDefs = (t: (value: string) => string) => [
  {
    cellRenderer: "agGroupCellRenderer",
    field: "",
    width: 50,
    suppressMovable: true,
    resizable: false,
    suppressMenu: true,
  },
  {
    headerName: t("bootle-date"),
    field: "bottleDate",
    ...excelMode,
    flex: 1,
    sortable: true,
    useValueFormatterForExport: true,
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.bottleDate);
    },
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.bottleDate);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
  },
  {
    headerName: t("compliance"),
    field: "complianceColor",
    cellRenderer: (valueFormatterParams: ValueFormatterParams) => {
      return (
        <ComplianceContainer>
          <Compliance color={valueFormatterParams?.value} />
        </ComplianceContainer>
      );
    },
    ...excelMode,
    hide: true,
    enableRowGroup: true,
  },
  {
    headerName: t("name"),
    field: "containerName",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("size"),
    field: "innerContainerSize",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("site"),
    field: "site",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("pack-type"),
    field: "packType",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("account"),
    field: "account",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("plc"),
    field: "packingSlipName",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("pack-date"),
    field: "packDate",
    ...excelMode,
    flex: 1,
    sortable: true,
    hide: true,
    useValueFormatterForExport: true,
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.pickupDate);
    },
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.pickupDate);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
  },
  {
    headerName: t("building"),
    field: "building",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("room"),
    field: "room",
    ...excelMode,
    flex: 1,
    hide: true,
  },
  {
    headerName: t("department"),
    field: "departmentName",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("pi"),
    field: "personalInvestigator",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("profile"),
    field: "profileNumber",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("manifest"),
    field: "manifestNumber",
    ...excelMode,
    flex: 1,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("saa"),
    field: "saa",
    ...excelMode,
    flex: 1,
    hide: true,
  },
  {
    headerName: t("waste-summary"),
    field: "wasteSummary",
    ...excelMode,
    flex: 1,
    hide: true,
  },
];

export const DetailCellRenderer = ({ data }: IDetailCellRendererParams) => {
  return <InventoryInformation inventory={data} />;
};
