import { t } from "i18next";
import { IAdditionalParam, IAdditionalParams } from "./types";

export const HTTP_METHOD = {
  POST: "POST",
  PUT: "PUT",
  GET: "GET",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const TOKEN = "Token";

export const RESPONSE_CODE = {
  NO_AUTH: 401,
  BAD_REQUEST: 400,
  INTERNAL_SERVER_ERROR: 500,
};

export const EXCEPTION_HANDLER_URL =
  "ExceptionHandler/ProcessUnhandledException";

export const REDUCER_PATH = "api";

export const SCOPES = [
  "api://a3fb7985-6b3e-46ea-b332-ce3ac4b0f3c9/access_as_user",
  "api://a3fb7985-6b3e-46ea-b332-ce3ac4b0f3c9/tasks.write",
];

export const getErrorMessage = (
  errorID?: string,
  additionalParams?: IAdditionalParams
) => {
  return t(
    errorID || "",
    additionalParams?.reduce(
      (acc: { [key: string]: string }, param: IAdditionalParam) => {
        acc[param?.paramName] = param?.paramValue;

        return acc;
      },
      {}
    )
  );
};
