import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import {
  PublicClientApplication,
  EventType,
  LogLevel,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import "./index.css";
import "./i18n";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { setupStore } from "./shared/store/store";
import { ThemeContextProvider } from "./shared/theme/themeContextProvider";
import { MsalProvider } from "@azure/msal-react";

export const store = setupStore();

export const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY_PATH,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI, // Indicates the page to navigate after logout.
    clientCapabilities: ["CP1"], // this lets the resource owner know that this client is capable of handling claims challenge.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      /**
       * Below you can configure MSAL.js logs. For more information, visit:
       * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
       */
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
    allowRedirectInIframe: true,
  },
});

publicClientApplication.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    console.log(event);
    const account = (event.payload as AuthenticationResult).account;

    publicClientApplication.setActiveAccount(account);
  }
});

(async () => {
  await publicClientApplication.initialize();
  await publicClientApplication.handleRedirectPromise();
})();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MsalProvider instance={publicClientApplication}>
    <ThemeContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeContextProvider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
