import React, { ChangeEvent, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { FormCard } from "src/shared/components/formCard/formCard";
import { ButtonWithIcon } from "src/shared/components/button/buttonWithIcon/buttonWithIcon";
import { ReactComponent as AddCircle } from "src/shared/assets/svgs/add-circle.svg";
import { ReactComponent as Trash } from "src/shared/assets/svgs/trash.svg";
import { useAppActions } from "src/shared/hooks/redux/redux";
import {
  useDeleteManifestBlobMutation,
  useUploadManifestBlobMutation,
} from "src/shared/store/api/manifest.api";
import { DeleteManifestBlobDialog } from "../deleteManifestBlobDialog/deleteManifestBlobDialog";
import { UploadedFile } from "../uploadedFile/uploadedFile";
import { IBlobFile } from "../inspectionsTable/inspectionInformation/types";
import { IUploadedFilesForm } from "./types";
import { MAX_FILE_SIZE, inputFileAccept } from "./constants";
import { UploadedFilesFormButtonWrapper, UploadedFileWrapper } from "./styles";

export const UploadedFilesForm = ({ blobFilePaths }: IUploadedFilesForm) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { manifestID } = useParams();
  const [selectedBlobFilePath, setSelectedBlobFilePath] = useState<IBlobFile>();
  const [isOpenDeleteManifestBLobDialog, setIsOpenDeleteManifestBlobDialog] =
    useState(false);
  const { setIsOpenSnackBar, setSnackBarMessages } = useAppActions();
  const [uploadManifestBlob, { isLoading: isLoadingUploadManifestBlob }] =
    useUploadManifestBlobMutation({});

  const [
    deleteManifestBlob,
    {
      isLoading: isLoadingDeleteManifestBlob,
      isSuccess: isSuccessDeleteManifestBLob,
    },
  ] = useDeleteManifestBlobMutation({});

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file?.size / 1024 > MAX_FILE_SIZE) {
      setSnackBarMessages([t("maximum-allowed-attachment")]);
      setIsOpenSnackBar(true);
    } else {
      const body = new FormData();
      body.append("file", file as File);
      uploadManifestBlob({
        manifestID,
        body,
      });
    }
  };

  const handleClickAttachFile = () => {
    inputRef?.current?.click();
  };

  const handleDeleteManifestBlob = (blobFilePath: IBlobFile) => () => {
    setSelectedBlobFilePath(blobFilePath);
    setIsOpenDeleteManifestBlobDialog(true);
  };

  const handleCloseDeleteManifestDialog = () => {
    setIsOpenDeleteManifestBlobDialog(false);
  };

  return (
    <BlockUi
      blocking={isLoadingDeleteManifestBlob || isLoadingUploadManifestBlob}
      tag="div"
      loader={<CircularProgress />}
      keepInView
    >
      <FormCard title={t("uploaded-files")}>
        <React.Fragment>
          {blobFilePaths?.map(({ file, folder, containerName }) => (
            <UploadedFileWrapper key={file}>
              <UploadedFile
                containerName={containerName}
                folder={folder}
                file={file}
              />
              <Trash
                onClick={handleDeleteManifestBlob({
                  containerName,
                  folder,
                  file,
                })}
              />
            </UploadedFileWrapper>
          ))}
          <UploadedFilesFormButtonWrapper>
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
              accept={inputFileAccept}
            />
            <ButtonWithIcon
              text={t("add-attachment")}
              onClick={handleClickAttachFile}
              icon={<AddCircle />}
              id="uploaded-manifest-add-attachment"
            />
          </UploadedFilesFormButtonWrapper>
        </React.Fragment>
      </FormCard>
      <DeleteManifestBlobDialog
        manifestID={manifestID}
        blobFilePath={selectedBlobFilePath}
        open={isOpenDeleteManifestBLobDialog}
        handleClose={handleCloseDeleteManifestDialog}
        deleteManifestBlob={deleteManifestBlob}
        isSuccessDeleteManifestBlob={isSuccessDeleteManifestBLob}
        isLoadingDeleteManifestBlob={isLoadingDeleteManifestBlob}
      />
    </BlockUi>
  );
};
