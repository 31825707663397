import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CellClickedEvent, SelectionChangedEvent } from "ag-grid-community";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { useAppActions } from "src/shared/hooks/redux/redux";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { useGetBannersQuery } from "src/shared/store/api/banner.api";
import { ISystemMessage } from "./types";
import {
  getColumnDefs,
  SYSTEM_MESSAGES_GRID_NAME,
  defaultColDef,
  rowGroupPanelShow,
  SYSTEM_MESSAGES_STORAGE_KEYS,
} from "./constants";

export const SystemMessagesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { history } = useHistory();

  const [selectedSystemMessage, setSelectedSystemMessage] =
    useState<ISystemMessage>();

  const previousLocation = history?.[history.length - 2] as string;
  const previousEditSystemMessageView =
    previousLocation &&
    previousLocation?.includes("edit-system-message") &&
    !previousLocation?.includes("site-admin");

  const { setSystemMessagesFilterScroll, setSystemMessagesFiltersSelectedRow } =
    useAppActions();

  const scrollToTop = getFromLocalStorage(
    SYSTEM_MESSAGES_STORAGE_KEYS.SCROLL_ROW
  );

  const {
    data: banners,
    isLoading: isLoadingBanners,
    isFetching: isFetchingBanners,
  } = useGetBannersQuery({});

  const columnDefs = getColumnDefs(t);

  const onCellClicked = (params: CellClickedEvent) => {
    setSystemMessagesFilterScroll(window.scrollY);
    setSystemMessagesFiltersSelectedRow(params?.data);
    if (params?.data?.id === selectedSystemMessage?.id) {
      navigate(`/edit-system-message/${params?.data?.id}`);
    }
  };

  const onSelectionChanged = useCallback((props: SelectionChangedEvent) => {
    const selectedRows = props?.api?.getSelectedRows();
    setSelectedSystemMessage(selectedRows[0]);
  }, []);

  useEffect(() => {
    if (typeof scrollToTop === "number" && previousEditSystemMessageView) {
      window.scroll(0, scrollToTop);
    }
  }, [scrollToTop, banners, previousEditSystemMessageView]);

  useEffect(() => {
    if (previousEditSystemMessageView) {
      saveToLocalStorage(
        SYSTEM_MESSAGES_STORAGE_KEYS.SELECTED_SYSTEM_MESSAGES,
        getFromLocalStorage(
          SYSTEM_MESSAGES_STORAGE_KEYS.SELECTED_SYSTEM_MESSAGES_ROW
        )
      );
    } else {
      saveToLocalStorage(
        SYSTEM_MESSAGES_STORAGE_KEYS.SELECTED_SYSTEM_MESSAGES,
        {}
      );
    }
  }, [previousEditSystemMessageView]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveToLocalStorage(
        SYSTEM_MESSAGES_STORAGE_KEYS.SELECTED_SYSTEM_MESSAGES,
        null
      );
      saveToLocalStorage(
        SYSTEM_MESSAGES_STORAGE_KEYS.SELECTED_SYSTEM_MESSAGES_ROW,
        null
      );
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <AgGrid
        title={t("system-messages")}
        gridName={SYSTEM_MESSAGES_GRID_NAME}
        rowData={banners}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        noRowsText={t("no-system-messages-display")}
        enableRangeSelection={true}
        rowGroupPanelShow={rowGroupPanelShow}
        rowSelection={"single"}
        isLoadingData={isLoadingBanners || isFetchingBanners}
        onCellClicked={onCellClicked}
        onSelectionChanged={onSelectionChanged}
        withSavingPage={!!previousEditSystemMessageView}
        withSavingSearch={!!previousEditSystemMessageView}
      />
    </>
  );
};
