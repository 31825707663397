import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { GridApi } from "ag-grid-community";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { useLazyInspectionResultSearchQuery } from "src/shared/store/api/inspection.api";
import { useThemeContext } from "src/shared/theme/themeContextProvider";
import { DARK } from "src/shared/hooks/theme/theme";
import { IInspection } from "src/components/detailInspectionCard/types";
import {
  PERMISSIONS_LIST,
  hasPermissions,
} from "src/shared/helpers/permissions";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import { CustomInspectionsTitle } from "./customInspectionsTitle/customInspectionsTitle";
import { InspectionsFilters } from "./inspectionsFilters/inspectionsFilters";
import { DeleteInspectionDialog } from "./deleteInspectionDialog/deleteInspectionDialog";
import {
  getColumnDefs,
  INSPECTIONS_GRID_NAME,
  defaultColDef,
  rowGroupPanelShow,
  DetailCellRenderer,
  getContextMenuItems,
} from "./constants";

export const InspectionsTable = () => {
  const { t } = useTranslation();
  const { mode } = useThemeContext();
  const { accounts } = useMsal();

  const [openDeleteInspectionDialog, setOpenDeleteInspectionDialog] =
    useState<boolean>(false);
  const [deleteInspectionSelected, setDeleteInspectionSelected] =
    useState<IInspection>();

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const permissions = userProfile?.userPermissions;

  const hasDeleteInspectionsPermission = hasPermissions(
    permissions,
    PERMISSIONS_LIST.DELETE_INSPECTIONS
  );

  const [
    getInspectionsData,
    {
      data: inspectionsData,
      isLoading: isLoadingInspections,
      isFetching: isFetchingInspections,
    },
  ] = useLazyInspectionResultSearchQuery();

  const customInspectionsFilters = (gridApi: GridApi | null) => (
    <InspectionsFilters
      getInspectionsData={getInspectionsData}
      gridApi={gridApi}
    />
  );

  const detailCellRenderer = useMemo(() => {
    return DetailCellRenderer;
  }, []);

  const columnDefs = getColumnDefs(t);

  const customTitle = (gridApi: GridApi | null) => (
    <CustomInspectionsTitle gridApi={gridApi} />
  );

  const contextMenuItems = getContextMenuItems(
    setDeleteInspectionSelected,
    setOpenDeleteInspectionDialog,
    t,
    mode === DARK,
    hasDeleteInspectionsPermission
  );

  const handleCloseDeleteInspectionDialog = () => {
    setOpenDeleteInspectionDialog(false);
  };

  return (
    <>
      <AgGrid
        customFilters={customInspectionsFilters}
        customTitle={customTitle}
        gridName={INSPECTIONS_GRID_NAME}
        rowData={inspectionsData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        noRowsText={t("no-inspections-display")}
        isLoadingData={isLoadingInspections || isFetchingInspections}
        enableRangeSelection={true}
        rowGroupPanelShow={rowGroupPanelShow}
        masterDetail={true}
        detailRowAutoHeight={true}
        detailCellRenderer={detailCellRenderer}
        getContextMenuItems={contextMenuItems}
      />
      <DeleteInspectionDialog
        inspection={deleteInspectionSelected}
        open={openDeleteInspectionDialog}
        handleClose={handleCloseDeleteInspectionDialog}
      />
    </>
  );
};
