import React from "react";
import {
  GroupCellRendererParams,
  IDetailCellRendererParams,
} from "ag-grid-enterprise";
import { ReactComponent as Star } from "src/shared/assets/svgs/star.svg";
import { ReactComponent as OrangeStar } from "src/shared/assets/svgs/orange-star.svg";
import { FavoriteContainer } from "./styles";
import { AccountInformation } from "./accountInformation/accountInformation";

export const paginationPageSize = 25;

export const defaultColDef = {
  sortable: true,
  resizable: true,
};

export const ACCOUNTS_GRID_NAME = "accounts";

export const getColumnDefs = (
  handleOnClickStar: (
    id: number,
    isFavorite: boolean,
    isDefault: boolean
  ) => () => void,
  t: (value: string) => string
) => [
  {
    cellRenderer: "agGroupCellRenderer",
    field: "",
    width: 50,
    suppressMovable: true,
    resizable: false,
    suppressMenu: true,
  },
  {
    headerName: t("account"),
    field: "name",
    cellRendererParams: {
      innerRenderer: ({ data }: GroupCellRendererParams) => {
        const {
          organizationUnitID,
          isDefault = false,
          isFavorite,
          name,
        } = data;

        return (
          <FavoriteContainer isFavorite={isFavorite}>
            {isFavorite ? (
              <OrangeStar
                onClick={handleOnClickStar(
                  organizationUnitID as number,
                  false,
                  isDefault
                )}
              />
            ) : (
              <Star
                onClick={handleOnClickStar(
                  organizationUnitID as number,
                  true,
                  isDefault
                )}
              />
            )}
            <React.Fragment>{name}</React.Fragment>
          </FavoriteContainer>
        );
      },
      colId: "action",
    },
  },
  { headerName: t("account-id"), field: "crmAccountID" },
  { headerName: t("phone"), field: "phoneNumber" },
  { headerName: t("city"), field: "city" },
  { headerName: t("state"), field: "state" },
];

export const DetailCellRenderer = ({ data }: IDetailCellRendererParams) => {
  return <AccountInformation account={data} />;
};
