import { ValueFormatterParams } from "ag-grid-community";
import {
  getLocaleDateValue,
  getSortedByMonthFormat,
} from "src/components/manifestTable/constants";

const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnDefs = (t: (value: string) => string) => [
  {
    headerName: t("profile-name"),
    field: "profileName",
    ...excelMode,
    flex: 1,
    sortable: true,
  },
  {
    headerName: t("contact-name"),
    field: "contactFullName",
    ...excelMode,
    flex: 1,
    sortable: true,
    hide: true,
    enableRowGroup: true,
  },
  {
    headerName: t("account"),
    field: "accountName",
    ...excelMode,
    flex: 1,
    sortable: true,
    hide: true,
    enableRowGroup: true,
  },
  {
    headerName: t("site"),
    field: "siteName",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("status"),
    field: "status",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
    useValueFormatterForExport: true,
  },
  {
    headerName: t("submit-date"),
    field: "submitDate",
    ...excelMode,
    flex: 1,
    sortable: true,
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.submitDate);
    },
    useValueFormatterForExport: true,
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    cellRenderer: "agGroupCellRenderer",
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.modifyDate);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
  },
  {
    headerName: t("create-date"),
    field: "createDate",
    ...excelMode,
    flex: 1,
    sortable: true,
    hide: true,
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.dateReturned);
    },
    useValueFormatterForExport: true,
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    cellRenderer: "agGroupCellRenderer",
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.createDate);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
  },
  {
    headerName: t("modify-date"),
    field: "modifyDate",
    ...excelMode,
    flex: 1,
    sortable: true,
    hide: true,
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.dateReturned);
    },
    useValueFormatterForExport: true,
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    cellRenderer: "agGroupCellRenderer",
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.modifyDate);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
  },
];

export const defaultColDef = {
  resizable: true,
};

export const paginationPageSize = 25;

export const REQUESTS_GRID_NAME = ["waste-profiles"];

export const REQUESTS_LABELS = ["profiles"];

export const rowGroupPanelShow = "always";

export const getRequestTabs = () =>
  REQUESTS_LABELS.map((label) => ({
    label,
    children: null,
  }));

export const getRequestOptions = (t: (val: string) => string) =>
  REQUESTS_LABELS.map((label) => ({ id: label, val: t(label) }));
