import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CellClickedEvent, SelectionChangedEvent } from "ag-grid-community";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { useGetAllInspectionTemplatesByUserAccountQuery } from "src/shared/store/api/inspection.api";
import { useAppActions } from "src/shared/hooks/redux/redux";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { IManageInspectionQuestionFields } from "src/components/manageInspectionQuestion/types";
import { CopyManageInspectionDialog } from "../copyManageInspectionDialog/copyManageInspectionDialog";
import {
  getColumnDefs,
  MANAGE_INSPECTIONS_GRID_NAME,
  defaultColDef,
  rowGroupPanelShow,
  MANAGE_INSPECTIONS_STORAGE_KEYS,
  getContextMenuItems,
} from "./constants";

export const ManageInspectionsTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { history } = useHistory();

  const [selectedManageInspection, setSelectedManageInspection] =
    useState<IManageInspectionQuestionFields>();
  const [manageInspectionId, setManageInspectionId] = useState<string>();
  const [openCopyManageInspectionDialog, setOpenCopyManageInspectionDialog] =
    useState<boolean>(false);

  const previousLocation = history?.[history.length - 2] as string;
  const previousManageInspectionView =
    previousLocation &&
    previousLocation?.includes("manage-inspection") &&
    !previousLocation?.includes("manage-inspections");

  const {
    setManageInspectionsFilterScroll,
    setManageInspectionsFiltersSelectedRow,
  } = useAppActions();

  const scrollToTop = getFromLocalStorage(
    MANAGE_INSPECTIONS_STORAGE_KEYS.SCROLL_ROW
  );

  const {
    data: manageInspections,
    isLoading: isLoadingManageInspection,
    isFetching: isFetchingManageInspections,
  } = useGetAllInspectionTemplatesByUserAccountQuery({});

  const columnDefs = getColumnDefs(t);

  const onCellClicked = (params: CellClickedEvent) => {
    setManageInspectionsFilterScroll(window.scrollY);
    setManageInspectionsFiltersSelectedRow(params?.data);
    if (params?.data?.inspectionID === selectedManageInspection?.inspectionID) {
      navigate(`/manage-inspection/${params?.data?.inspectionID}`);
    }
  };

  const onSelectionChanged = useCallback((props: SelectionChangedEvent) => {
    const selectedRows = props?.api?.getSelectedRows();
    setSelectedManageInspection(selectedRows[0]);
  }, []);

  const modifiedManageInspections = useMemo(
    () =>
      manageInspections?.map((inspection: IManageInspectionQuestionFields) => ({
        id: inspection?.inspectionID,
        ...inspection,
      })),
    [manageInspections]
  );

  const contextMenuItems = getContextMenuItems(
    t,
    setManageInspectionId,
    setOpenCopyManageInspectionDialog
  );

  const handleCloseCopyManageInspectionDialog = () => {
    setOpenCopyManageInspectionDialog(false);
  };

  useEffect(() => {
    if (typeof scrollToTop === "number" && previousManageInspectionView) {
      window.scroll(0, scrollToTop);
    }
  }, [scrollToTop, manageInspections, previousManageInspectionView]);

  useEffect(() => {
    if (previousManageInspectionView) {
      saveToLocalStorage(
        MANAGE_INSPECTIONS_STORAGE_KEYS.SELECTED_MANAGE_INSPECTIONS,
        getFromLocalStorage(
          MANAGE_INSPECTIONS_STORAGE_KEYS.SELECTED_MANAGE_INSPECTIONS_ROW
        )
      );
    } else {
      saveToLocalStorage(
        MANAGE_INSPECTIONS_STORAGE_KEYS.SELECTED_MANAGE_INSPECTIONS,
        {}
      );
    }
  }, [previousManageInspectionView]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveToLocalStorage(
        MANAGE_INSPECTIONS_STORAGE_KEYS.SELECTED_MANAGE_INSPECTIONS,
        null
      );
      saveToLocalStorage(
        MANAGE_INSPECTIONS_STORAGE_KEYS.SELECTED_MANAGE_INSPECTIONS_ROW,
        null
      );
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <AgGrid
        title={t("manage-inspections")}
        gridName={MANAGE_INSPECTIONS_GRID_NAME}
        rowData={modifiedManageInspections}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        noRowsText={t("no-manage-inspections-display")}
        enableRangeSelection={true}
        rowGroupPanelShow={rowGroupPanelShow}
        rowSelection={"single"}
        isLoadingData={isLoadingManageInspection || isFetchingManageInspections}
        onCellClicked={onCellClicked}
        onSelectionChanged={onSelectionChanged}
        withSavingPage={!!previousManageInspectionView}
        withSavingSearch={!!previousManageInspectionView}
        getContextMenuItems={contextMenuItems}
      />
      <CopyManageInspectionDialog
        manageInspectionId={manageInspectionId}
        open={openCopyManageInspectionDialog}
        handleClose={handleCloseCopyManageInspectionDialog}
      />
    </>
  );
};
