import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const NewUserContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: 368,
  [theme.breakpoints.down("md")]: {
    padding: 16,
  },
}));

export const NewUserTitle = styled(Typography)(({ theme }) => ({
  color: "#282828",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textAlign: "center",
  marginTop: 59,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    marginTop: 54,
  },
}));

export const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  marginTop: 32,
  [theme.breakpoints.down("md")]: {
    gap: 16,
    marginTop: 24,
  },
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  marginTop: 32,
  [theme.breakpoints.down("md")]: {
    marginTop: 24,
  },
}));
