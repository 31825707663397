import { generatedIdForTextArea } from "src/shared/helpers/generatedId";
import { StyledTextArea, ErrorText, TextAreaContainer } from "./styles";
import { ITextArea } from "./types";

export const TextArea = ({
  value,
  onChange,
  disabled,
  errorMessage,
  register,
  placeholder,
  minRows,
  id,
}: ITextArea) => {
  return (
    <TextAreaContainer>
      <StyledTextArea
        minRows={minRows}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={Boolean(errorMessage) || false}
        id={generatedIdForTextArea(id)}
        {...register}
      />
      <ErrorText>{errorMessage}</ErrorText>
    </TextAreaContainer>
  );
};
