import { api } from "./api";
import { HTTP_METHOD } from "./constants";

const MANIFEST_SUB_URL = "Manifest";

export const manifest = api.injectEndpoints({
  endpoints: (builder) => ({
    manifestSearch: builder.query({
      query: (body) => ({
        url: `${MANIFEST_SUB_URL}/ManifestSearch`,
        method: HTTP_METHOD.POST,
        ...(body ? { body } : {}),
      }),
      providesTags: ["Manifest"],
    }),
    updateManifestStatus: builder.mutation({
      query: ({ body }) => ({
        url: `${MANIFEST_SUB_URL}/UpdateManifestStatus`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["Manifest"],
    }),
    getManifestByID: builder.query({
      query: ({ manifestID }) => ({
        url: `${MANIFEST_SUB_URL}/GetManifestByID/${manifestID}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["Manifest"],
    }),
    manifestListExportFile: builder.mutation({
      queryFn: async (body, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${MANIFEST_SUB_URL}/ManifestListExportFile`,
          method: HTTP_METHOD.POST,
          body,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blobExcel = url.createObjectURL(result?.data);
        hiddenElement.href = blobExcel;
        hiddenElement.target = "_blank";
        hiddenElement.download = "manifestList.xlsx";
        hiddenElement.click();
        return { data: null };
      },
    }),
    manifestDetailExportFile: builder.mutation({
      queryFn: async (body, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${MANIFEST_SUB_URL}/ManifestDetailExportFile`,
          method: HTTP_METHOD.POST,
          body,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blobExcel = url.createObjectURL(result?.data);
        hiddenElement.href = blobExcel;
        hiddenElement.target = "_blank";
        hiddenElement.download = "wasteDetail.xlsx";
        hiddenElement.click();
        return { data: null };
      },
    }),
    GetEManifest: builder.mutation({
      queryFn: async (id, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${MANIFEST_SUB_URL}/GetEmanifest/${id}`,
          method: HTTP_METHOD.GET,
          responseHandler: (response) =>
            response.status === 200 ? response.blob() : response.json(),
        });
        if (result?.data) {
          const hiddenElement = document.createElement("a");
          const url = window.URL || window.webkitURL;
          const blob = url.createObjectURL(result?.data);
          hiddenElement.href = blob;
          hiddenElement.target = "_blank";
          hiddenElement.click();
        }

        return { data: null };
      },
      invalidatesTags: ["Manifest"],
    }),
    uploadManifestBlob: builder.mutation({
      query: ({ manifestID, body }) => ({
        url: `${MANIFEST_SUB_URL}/UploadManifestBlob/${manifestID}`,
        method: HTTP_METHOD.POST,
        formData: true,
        body,
      }),
      invalidatesTags: ["Manifest"],
    }),
    deleteManifestBlob: builder.mutation({
      query: ({ manifestID, containerName, folderName, fileName }) => ({
        url: `${MANIFEST_SUB_URL}/DeleteManifestBlob/${manifestID}/${containerName}/${folderName}/${fileName}`,
        method: HTTP_METHOD.DELETE,
      }),
      invalidatesTags: ["Manifest"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeleteManifestBlobMutation,
  useLazyManifestSearchQuery,
  useUpdateManifestStatusMutation,
  useGetManifestByIDQuery,
  useManifestDetailExportFileMutation,
  useManifestListExportFileMutation,
  useGetEManifestMutation,
  useUploadManifestBlobMutation,
} = manifest;
