import { HTTP_METHOD } from "./constants";
import { api } from "./api";

const ORGANIZATION_SUB_URL = "Organization";

export const organization = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationBySearchValue: builder.query({
      query: ({ searchValue }) => ({
        url: `${ORGANIZATION_SUB_URL}/GetOrganizationsBySearchValue/${searchValue}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getOrganizationsByIDs: builder.query({
      query: (organizationUnitIDs) => ({
        url: `${ORGANIZATION_SUB_URL}/GetOrganizationsByIDs/${organizationUnitIDs}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["Organization"],
    }),
    getOrganizationSites: builder.query({
      query: ({ organizationId }) => ({
        url: `${ORGANIZATION_SUB_URL}/GetOrganizationSites/${organizationId}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getAllOrganizationFacilities: builder.query({
      query: ({ isSiteRequest, accountIDList, siteIDList }) => ({
        url: isSiteRequest
          ? `${ORGANIZATION_SUB_URL}/GetOrganizationFacilitiesBySiteIDs/${siteIDList}`
          : `${ORGANIZATION_SUB_URL}/GetOrganizationFacilitiesByAccountIDs/${accountIDList}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getSiteByOrganizationByID: builder.query({
      query: (organizationIds) => ({
        url: `${ORGANIZATION_SUB_URL}/GetSiteByOrganizationByID/${organizationIds}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UpdateAging"],
    }),
    getBuildingByOrganizationByID: builder.query({
      query: (organizationIds) => ({
        url: `${ORGANIZATION_SUB_URL}/GetBuildingByOrganizationByID/${organizationIds}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getRoomByOrganizationByID: builder.query({
      query: (organizationIds) => ({
        url: `${ORGANIZATION_SUB_URL}/GetRoomByOrganizationByID/${organizationIds}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getBuildingOrganizationsBySiteParentIDs: builder.query({
      query: (organizationIds) => ({
        url: `${ORGANIZATION_SUB_URL}/GetBuildingOrganizationsBySiteParentIDs/${organizationIds}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getRoomsOrganizationsBySiteParentIDs: builder.query({
      query: (organizationIds) => ({
        url: `${ORGANIZATION_SUB_URL}/GetRoomsOrganizationsBySiteParentIDs/${organizationIds}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getSiteOrganizationsByIDs: builder.query({
      query: (organizationIds) => ({
        url: `${ORGANIZATION_SUB_URL}/GetSiteOrganizationsByIDs/${organizationIds}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getSiteTreeExportByAccountID: builder.query({
      queryFn: async (organizationUnitID, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${ORGANIZATION_SUB_URL}/GetSiteTreeExportByAccountID/${organizationUnitID}`,
          method: HTTP_METHOD.GET,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blob = url.createObjectURL(result?.data);
        hiddenElement.href = blob;
        hiddenElement.target = "_blank";
        hiddenElement.download = "siteTreeList";
        hiddenElement.click();
        return { data: null };
      },
    }),
    getLocationsOrganizationsByRoomParentIDs: builder.query({
      query: (organizationUnitIDs) => ({
        url: `${ORGANIZATION_SUB_URL}/GetLocationsOrganizationsByRoomParentIDs/${organizationUnitIDs}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UpdateLocation"],
    }),
    getInventoryLocations: builder.query({
      query: (organizationUnitIDs) => ({
        url: `${ORGANIZATION_SUB_URL}/GetInventoryLocations/${organizationUnitIDs}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UpdateLocation"],
    }),
    getLocationByIdAndDocType: builder.query({
      query: ({ locationID, docType }) => ({
        url: `${ORGANIZATION_SUB_URL}/GetLocationByIdAndDocType/${locationID}/${docType}`,
        method: HTTP_METHOD.GET,
      }),
      providesTags: ["UpdateLocation"],
    }),
    upsertSiteAging: builder.mutation({
      query: ({ body, siteOrganizationUnitID }) => ({
        url: `${ORGANIZATION_SUB_URL}/UpsertSiteAging?siteOrganizationUnitID=${siteOrganizationUnitID}`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["UpdateAging"],
    }),
    getLocationTypes: builder.query({
      query: () => ({
        url: `${ORGANIZATION_SUB_URL}/GetLocationTypes`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getContactsByAccountOrganizationUnitID: builder.query({
      query: (organizationUnitID) => ({
        url: `${ORGANIZATION_SUB_URL}/GetContactsByAccountOrganizationUnitID/${organizationUnitID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getDepartmentsByAccountOrganizationUnitID: builder.query({
      query: (organizationUnitID) => ({
        url: `${ORGANIZATION_SUB_URL}/GetDepartmentsByAccountOrganizationUnitID/${organizationUnitID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    getAccountOrganizationUnitIDByRoomID: builder.query({
      query: (roomID) => ({
        url: `${ORGANIZATION_SUB_URL}/GetAccountOrganizationUnitIDByRoomID/${roomID}`,
        method: HTTP_METHOD.GET,
      }),
    }),
    upsertLocation: builder.mutation({
      query: (body) => ({
        url: `${ORGANIZATION_SUB_URL}/upsertLocation`,
        method: HTTP_METHOD.POST,
        body,
      }),
      invalidatesTags: ["UpdateLocation"],
    }),
    deactivateLocation: builder.mutation({
      query: (id) => ({
        url: `${ORGANIZATION_SUB_URL}/DeactivateLocation/${id}`,
        method: HTTP_METHOD.POST,
      }),
      invalidatesTags: ["UpdateLocation"],
    }),
    getGeneratedBarcode: builder.query({
      query: () => ({
        url: `${ORGANIZATION_SUB_URL}/GetGeneratedBarcode`,
        method: HTTP_METHOD.GET,
        responseHandler: "text",
      }),
    }),
    generatedPdfWithBarcodeByAccountID: builder.query({
      queryFn: async (accountID, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${ORGANIZATION_SUB_URL}/GeneratedPdfWithBarcodeByAccountID/${accountID}`,
          method: HTTP_METHOD.GET,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blob = url.createObjectURL(result?.data);
        hiddenElement.href = blob;
        hiddenElement.target = "_blank";
        hiddenElement.download = `barcodeByAccountID(${accountID})`;
        hiddenElement.click();
        return { data: null };
      },
    }),
    generatedPdfWithBarcodeBySiteID: builder.query({
      queryFn: async (accountID, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${ORGANIZATION_SUB_URL}/generatedPdfWithBarcodeBySiteID/${accountID}`,
          method: HTTP_METHOD.GET,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blob = url.createObjectURL(result?.data);
        hiddenElement.href = blob;
        hiddenElement.target = "_blank";
        hiddenElement.download = `barcodeBySiteID(${accountID})`;
        hiddenElement.click();
        return { data: null };
      },
    }),
    generatedPdfWithBarcodeByBuildingID: builder.query({
      queryFn: async (buildingID, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${ORGANIZATION_SUB_URL}/GeneratedPdfWithBarcodeByBuildingID/${buildingID}`,
          method: HTTP_METHOD.GET,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blob = url.createObjectURL(result?.data);
        hiddenElement.href = blob;
        hiddenElement.target = "_blank";
        hiddenElement.download = `barcodeByBuildingID(${buildingID})`;
        hiddenElement.click();
        return { data: null };
      },
    }),
    generatedPdfWithBarcodeByRoomID: builder.query({
      queryFn: async (roomID, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${ORGANIZATION_SUB_URL}/GeneratedPdfWithBarcodeByRoomID/${roomID}`,
          method: HTTP_METHOD.GET,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blob = url.createObjectURL(result?.data);
        hiddenElement.href = blob;
        hiddenElement.target = "_blank";
        hiddenElement.download = `barcodeByRoomID(${roomID})`;
        hiddenElement.click();
        return { data: null };
      },
    }),
    generatedPdfWithBarcodeByLocationID: builder.query({
      queryFn: async (locationID, _, __, baseQuery) => {
        const result = await baseQuery({
          url: `${ORGANIZATION_SUB_URL}/GeneratedPdfWithBarcodeByLocationID/${locationID}`,
          method: HTTP_METHOD.GET,
          responseHandler: (response) => response.blob(),
        });
        const hiddenElement = document.createElement("a");
        const url = window.URL || window.webkitURL;
        const blob = url.createObjectURL(result?.data);
        hiddenElement.href = blob;
        hiddenElement.target = "_blank";
        hiddenElement.download = `barcodeByLocationID(${locationID})`;
        hiddenElement.click();
        return { data: null };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGeneratedPdfWithBarcodeByRoomIDQuery,
  useLazyGeneratedPdfWithBarcodeByLocationIDQuery,
  useLazyGeneratedPdfWithBarcodeBySiteIDQuery,
  useLazyGeneratedPdfWithBarcodeByAccountIDQuery,
  useGetGeneratedBarcodeQuery,
  useDeactivateLocationMutation,
  useUpsertLocationMutation,
  useGetAccountOrganizationUnitIDByRoomIDQuery,
  useGetLocationTypesQuery,
  useGetContactsByAccountOrganizationUnitIDQuery,
  useGetDepartmentsByAccountOrganizationUnitIDQuery,
  useUpsertSiteAgingMutation,
  useGetLocationByIdAndDocTypeQuery,
  useGetLocationsOrganizationsByRoomParentIDsQuery,
  useGetInventoryLocationsQuery,
  useLazyGetSiteTreeExportByAccountIDQuery,
  useGetSiteOrganizationsByIDsQuery,
  useGetBuildingByOrganizationByIDQuery,
  useGetRoomByOrganizationByIDQuery,
  useGetBuildingOrganizationsBySiteParentIDsQuery,
  useGetRoomsOrganizationsBySiteParentIDsQuery,
  useGetSiteByOrganizationByIDQuery,
  useGetOrganizationBySearchValueQuery,
  useGetOrganizationsByIDsQuery,
  useGetOrganizationSitesQuery,
  useLazyGetAllOrganizationFacilitiesQuery,
  useLazyGeneratedPdfWithBarcodeByBuildingIDQuery,
} = organization;
