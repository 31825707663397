import {
  GetContextMenuItemsParams,
  GroupCellRendererParams,
} from "ag-grid-enterprise";
import { ReactComponent as Trash } from "src/shared/assets/svgs/trash.svg";
import NewTab from "src/shared/assets/svgs/new-tab.svg";
import WhiteNewTab from "src/shared/assets/svgs/white-new-tab.svg";
import { DeleteIconWrapper } from "./styles";

export const getColumnDefs = (t: (value: string) => string) => [
  {
    headerName: t("first-name"),
    field: "firstName",
  },
  {
    headerName: t("last-name"),
    field: "lastName",
  },
  {
    headerName: t("email"),
    field: "email",
  },
  {
    headerName: t("job-title"),
    field: "jobTitle",
  },
  {
    headerName: t("organization-name"),
    field: "organizationName",
    enableRowGroup: true,
  },
  {
    headerName: t("phone"),
    field: "phone",
  },
  {
    headerName: t("action"),
    cellRenderer: ({ data }: GroupCellRendererParams) =>
      data ? (
        <DeleteIconWrapper>
          <Trash data-action="delete" />
        </DeleteIconWrapper>
      ) : null,
    editable: false,
    colId: "action",
  },
];

export const paginationPageSize = 25;

export const defaultColDef = {
  flex: 1,
  sortable: true,
  resizable: true,
};

export const USERS_GRID_NAME = "users";

export const rowGroupPanelShow = "always";

export const getContextMenuItems =
  (t: (value: string) => string, isDark: boolean) =>
  (params: GetContextMenuItemsParams) =>
    [
      {
        name: t("open-in-new-tab"),
        action: () => {
          window.open(
            `${window.location.origin}/edit-user/${params?.node?.data?.id}`,
            "_blank"
          );
        },
        icon: `<img src="${isDark ? WhiteNewTab : NewTab}"/>`,
      },
      "cut",
      "copy",
      "copyWithHeaders",
      "copyWithGroupHeaders",
      "paste",
      "separator",
      "export",
    ];

export const USERS_STORAGE_KEYS = {
  KEYWORD: "PROFILES_KEYWORD",
  SELECTED_USERS_ROW: "SELECTED_USERS_ROW",
  SELECTED_USERS: "selected_users",
};
