import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { RadioGroup } from "@mui/material";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { Input } from "src/shared/components/input/input";
import question from "src/shared/assets/svgs/question.svg";
import { Question } from "src/components/permissitonsForm/styles";
import { ReactComponent as FilterIcon } from "src/shared/assets/svgs/filter-icon.svg";
import { InspectionsTooltip } from "./inspectionsTooltip/inspectionsTooltip";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { Button } from "src/shared/components/button/button";
import { useGetUserProfileByIDQuery } from "src/shared/store/api/userProfile.api";
import {
  useGetBuildingOrganizationsBySiteParentIDsQuery,
  useGetContactsByAccountOrganizationUnitIDQuery,
  useGetDepartmentsByAccountOrganizationUnitIDQuery,
  useGetLocationsOrganizationsByRoomParentIDsQuery,
  useGetOrganizationBySearchValueQuery,
  useGetOrganizationSitesQuery,
  useGetOrganizationsByIDsQuery,
  useGetRoomsOrganizationsBySiteParentIDsQuery,
} from "src/shared/store/api/organization.api";
import { IOrganization } from "src/components/quickOverview/types";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { IUserAccount } from "src/components/editAssignedAccountsForm/types";
import { IOption } from "src/shared/components/select/types";
import { IBuilding } from "src/components/buildingsList/types";
import { IRoom } from "src/pages/buildingInformation/types";
import { DatePicker } from "src/shared/components/datePicker/datePicker";
import { getContactOptions } from "src/components/editLocationParameters/constants";
import { IDepartment } from "src/components/editLocationParameters/types";
import {
  useGetFollowUpOptionsQuery,
  useGetInspectionTemplatesByAccountQuery,
} from "src/shared/store/api/inspection.api";
import { ReactComponent as CheckedIcon } from "src/shared/assets/svgs/checked-radio-button.svg";
import { IInspectionsFilters } from "./types";
import {
  HAS_VALUES_OPTIONS,
  getFollowUpOptions,
  getListOfIdsFromObject,
  getHasValue,
  scoreComparisonOptions,
  getComparisonValue,
  getInspectionsTypeOptions,
} from "./constants";
import {
  ActionsWrapper,
  FilterIconWrapper,
  FilterWrapper,
  FiltersContainer,
  FiltersWrapper,
  InspectionsFiltersContainer,
  InspectionsSearchWrapper,
  Label,
  InspectionsFiltersWrapper,
  Radio,
  FormControlLabel,
  RadioGroupWrapper,
  ScoreWrapper,
} from "./styles";

export const InspectionsFilters = ({
  gridApi,
  getInspectionsData,
}: IInspectionsFilters) => {
  const { t } = useTranslation();
  const { accounts } = useMsal();

  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [defaultOrganizationId, setDefaultOrganizationId] = useState<string>();
  const [isOpenInspectionsTooltip, setIsOpenInspectionsTooltip] =
    useState<boolean>(false);

  const {
    keyword,
    searchOrganization,
    account: inspectionsAccount,
    sites,
    buildings,
    rooms,
    locations,
    contacts,
    departments,
    startDate,
    endDate,
    followUp,
    scoreComparison,
    score,
    hasAttachments,
    hasComments,
    inspectionType,
  } = useAppSelector((state) => state.inspectionsFiltersReducer);

  const {
    setInspectionsFiltersAccount,
    setInspectionsFiltersSearchOrganization,
    setInspectionsFiltersKeyword,
    setInspectionsFilterSites,
    setInspectionsFilterBuildings,
    setInspectionsFilterRooms,
    setInspectionsFilterLocations,
    setInspectionsFilterDepartments,
    setInspectionsFilterContacts,
    setInspectionsFilterStartDate,
    setInspectionsFilterEndDate,
    setInspectionsFiltersFollowUp,
    setInspectionsFiltersScoreComparison,
    setInspectionsFiltersScore,
    setInspectionsFiltersHasAttachments,
    setInspectionsFiltersHasComments,
    setInspectionsFiltersInspectionType,
    clearFromLocalStorageInspectionsFilters,
  } = useAppActions();

  const getInspections = useCallback(
    (newKeyword: string) => {
      getInspectionsData({
        keyword: newKeyword,
        startDate,
        endDate,
        accountIDList: [inspectionsAccount?.id],
        siteIDList: getListOfIdsFromObject(sites),
        buildingIDList: getListOfIdsFromObject(buildings),
        roomIDList: getListOfIdsFromObject(rooms),
        storageIDList: getListOfIdsFromObject(locations),
        departmentIDList: getListOfIdsFromObject(departments),
        followUpOptionIDList: getListOfIdsFromObject(followUp),
        contactIDList: getListOfIdsFromObject(contacts),
        hasAttachments: getHasValue(hasAttachments),
        hasComments: getHasValue(hasComments),
        inspectionID: inspectionType?.id,
        ...(scoreComparison
          ? {
              score: {
                operator: getComparisonValue(scoreComparison?.val),
                scoreValue: Number(score) || 0,
              },
            }
          : {}),
      });
    },
    [
      getInspectionsData,
      startDate,
      endDate,
      inspectionsAccount?.id,
      sites,
      buildings,
      rooms,
      locations,
      departments,
      followUp,
      contacts,
      hasAttachments,
      hasComments,
      inspectionType?.id,
      scoreComparison,
      score,
    ]
  );

  const { data: defaultOrganization } = useGetOrganizationsByIDsQuery(
    defaultOrganizationId,
    { skip: !defaultOrganizationId }
  );

  const { data: userProfile = {} } = useGetUserProfileByIDQuery(
    {
      id: accounts[0]?.username,
    },
    {
      skip: !accounts[0]?.username,
    }
  );

  const { data: organizationOptions } = useGetOrganizationBySearchValueQuery({
    searchValue: searchOrganization,
  });

  const { data: inspectionTypeOptions } =
    useGetInspectionTemplatesByAccountQuery(inspectionsAccount?.id, {
      skip: !inspectionsAccount?.id,
    });

  const { data: buildingsOptions } =
    useGetBuildingOrganizationsBySiteParentIDsQuery(
      getListOfIdsFromObject(sites),
      {
        skip: !sites?.length,
      }
    );

  const { data: roomsOptions } = useGetRoomsOrganizationsBySiteParentIDsQuery(
    getListOfIdsFromObject(buildings),
    {
      skip: !buildings?.length,
    }
  );

  const { data: locationsOptions } =
    useGetLocationsOrganizationsByRoomParentIDsQuery(
      getListOfIdsFromObject(rooms),
      {
        skip: !rooms?.length,
      }
    );

  const { data: contactsOptions } =
    useGetContactsByAccountOrganizationUnitIDQuery(inspectionsAccount?.id, {
      skip: !inspectionsAccount,
    });

  const { data: organizationSites } = useGetOrganizationSitesQuery(
    {
      organizationId: inspectionsAccount?.id,
    },
    {
      skip: !inspectionsAccount,
    }
  );

  const { data: departmentOptions } =
    useGetDepartmentsByAccountOrganizationUnitIDQuery(inspectionsAccount?.id, {
      skip: !inspectionsAccount,
    });

  const { data: followUpOptions } = useGetFollowUpOptionsQuery({});

  const handleOpenInspectionsTooltip = () => {
    setIsOpenInspectionsTooltip(true);
  };

  const handleChangeOrganization = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInspectionsFiltersAccount(newInputValue as Array<IOrganization>);
    setInspectionsFilterSites([]);
    setInspectionsFilterBuildings([]);
    setInspectionsFilterRooms([]);
    setInspectionsFilterLocations([]);
    setInspectionsFilterContacts([]);
    setInspectionsFilterDepartments([]);
  };

  const handleChangeInspectionType = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInspectionsFiltersInspectionType(newInputValue);
  };

  const handleChangeScoreComparison = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInspectionsFiltersScoreComparison(newInputValue);
  };

  const handleChangeSite = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInspectionsFilterSites(newInputValue as Array<IOption>);
    setInspectionsFilterBuildings([]);
    setInspectionsFilterRooms([]);
    setInspectionsFilterLocations([]);
    setInspectionsFilterContacts([]);
  };

  const handleChangeBuildings = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInspectionsFilterBuildings(newInputValue as Array<IOption>);
    setInspectionsFilterRooms([]);
    setInspectionsFilterLocations([]);
    setInspectionsFilterContacts([]);
  };

  const handleChangeRooms = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInspectionsFilterRooms(newInputValue as Array<IOption>);
    setInspectionsFilterLocations([]);
    setInspectionsFilterContacts([]);
  };

  const handleChangeLocations = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInspectionsFilterLocations(newInputValue as Array<IOption>);
    setInspectionsFilterContacts([]);
  };

  const handleChangeContacts = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInspectionsFilterContacts(newInputValue as Array<IOption>);
  };

  const handleChangeDepartments = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInspectionsFilterDepartments(newInputValue as Array<IOption>);
  };

  const handleChangeFollowUp = (
    _: SyntheticEvent<Element, Event>,
    newInputValue: unknown
  ) => {
    setInspectionsFiltersFollowUp(newInputValue as Array<IOption>);
  };

  const handleChangeStartDate = (date: unknown) => {
    if (date) {
      setInspectionsFilterStartDate(date as Date);
    }
  };

  const handleChangeEndDate = (date: unknown) => {
    if (date) {
      setInspectionsFilterEndDate(date as Date);
    }
  };

  const handleFilters = () => setIsOpenFilters(!isOpenFilters);

  const handleApplyFilters = () => {
    getInspections(keyword);
    gridApi?.paginationGoToPage(0);
  };

  const handleClearFilters = () => {
    gridApi?.setFilterModel(null);
    clearFromLocalStorageInspectionsFilters();
    setInspectionsFiltersKeyword("");
    setInspectionsFiltersSearchOrganization("");
    setInspectionsFiltersAccount({
      id: defaultOrganization?.[0]?.organizationUnitID,
      val: defaultOrganization?.[0]?.name,
    });
    getInspectionsData({
      keyword: "",
      startDate: dayjs(new Date()).subtract(365, "days"),
      endDate: dayjs(new Date()),
      accountIDList: [defaultOrganization?.[0]?.organizationUnitID],
      siteIDList: [],
      buildingIDList: [],
      roomIDList: [],
      storageIDList: [],
      contactIDList: [],
      departmentIDList: [],
      followUpOptionIDList: [],
    });
  };

  const handleChangeScore = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    const regular = /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/;

    if (value.match(regular)) {
      setInspectionsFiltersScore(value);
    }
  };

  const handleChangeHasAttachments = (event: ChangeEvent<HTMLInputElement>) => {
    setInspectionsFiltersHasAttachments(event?.target?.value);
  };

  const handleChangeHasComments = (event: ChangeEvent<HTMLInputElement>) => {
    setInspectionsFiltersHasComments(event?.target?.value);
  };

  useEffect(() => {
    if (userProfile?.userAccounts) {
      const defaultOrganization = userProfile?.userAccounts?.find(
        (account: IUserAccount) => !!account.isDefault
      );
      if (defaultOrganization) {
        setDefaultOrganizationId(defaultOrganization?.organizationUnitID);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (defaultOrganization && !inspectionsAccount) {
      const defaultAccountList = {
        id: defaultOrganization?.[0]?.organizationUnitID,
        val: defaultOrganization?.[0]?.name,
      };
      setInspectionsFiltersAccount(defaultAccountList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganization]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedTrigger = useCallback(
    debounce((keyword) => getInspections(keyword), 2000),
    [getInspections]
  );

  const onChangeSearchInspections = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInspectionsFiltersKeyword(value);
      debouncedTrigger(value);
    },
    [debouncedTrigger, setInspectionsFiltersKeyword]
  );

  useEffect(() => {
    getInspectionsData({
      keyword,
      startDate,
      endDate,
      accountIDList: inspectionsAccount
        ? [inspectionsAccount?.id]
        : defaultOrganizationId
        ? [defaultOrganizationId]
        : undefined,
      siteIDList: getListOfIdsFromObject(sites),
      buildingIDList: getListOfIdsFromObject(buildings),
      roomIDList: getListOfIdsFromObject(rooms),
      storageIDList: getListOfIdsFromObject(locations),
      departmentIDList: getListOfIdsFromObject(departments),
      followUpOptionIDList: getListOfIdsFromObject(followUp),
      contactIDList: getListOfIdsFromObject(contacts),
      hasAttachments: getHasValue(hasAttachments),
      hasComments: getHasValue(hasComments),
      inspectionID: inspectionType?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOrganizationId]);

  return (
    <InspectionsFiltersContainer>
      <InspectionsSearchWrapper>
        <Input
          value={keyword}
          placeholder={t("search-in-inspections")}
          onChange={onChangeSearchInspections}
          InputProps={{
            endAdornment: (
              <FilterIconWrapper position="end">
                <FilterIcon onClick={handleFilters} />
              </FilterIconWrapper>
            ),
          }}
          id="search-in-inspections"
        />
        <Question
          src={question}
          alt=""
          onClick={handleOpenInspectionsTooltip}
        />
        <InspectionsTooltip
          open={isOpenInspectionsTooltip}
          setOpen={setIsOpenInspectionsTooltip}
        />
      </InspectionsSearchWrapper>
      {isOpenFilters && (
        <>
          <InspectionsFiltersWrapper>
            {t("please-use-all-filters")}
          </InspectionsFiltersWrapper>
          {userProfile?.userAccounts?.length > 1 && (
            <FiltersContainer>
              <FiltersWrapper>
                <FilterWrapper>
                  <Label>{t("account")}</Label>
                  <Autocomplete
                    options={organizationOptions ? organizationOptions : []}
                    value={inspectionsAccount || null}
                    onChange={handleChangeOrganization}
                    id="inspections-filters-account"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("site")}</Label>
                  <Autocomplete
                    value={sites || null}
                    onChange={handleChangeSite}
                    options={organizationSites ? organizationSites : []}
                    disabled={!organizationSites?.length || !inspectionsAccount}
                    multiple
                    id="inspections-filters-site"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("building")}</Label>
                  <Autocomplete
                    value={buildings || null}
                    onChange={handleChangeBuildings}
                    options={
                      buildingsOptions?.length
                        ? buildingsOptions?.map(
                            ({ organizationUnitID, name }: IBuilding) => ({
                              id: organizationUnitID,
                              val: name,
                            })
                          )
                        : []
                    }
                    disabled={!buildingsOptions?.length || !sites?.length}
                    multiple
                    id="inspections-filters-buildings"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("room")}</Label>
                  <Autocomplete
                    value={rooms || null}
                    onChange={handleChangeRooms}
                    options={
                      roomsOptions?.length
                        ? roomsOptions?.map(
                            ({ organizationUnitID, name }: IRoom) => ({
                              id: organizationUnitID,
                              val: name,
                            })
                          )
                        : []
                    }
                    disabled={!roomsOptions?.length || !buildings?.length}
                    multiple
                    id="inspections-filters-rooms"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("location")}</Label>
                  <Autocomplete
                    value={locations || null}
                    onChange={handleChangeLocations}
                    options={
                      locationsOptions?.length
                        ? locationsOptions?.map(({ id, name }: IRoom) => ({
                            id,
                            val: name,
                          }))
                        : []
                    }
                    disabled={!locationsOptions?.length || !rooms?.length}
                    multiple
                    id="inspections-filters-locations"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("department")}</Label>
                  <Autocomplete
                    value={departments || null}
                    onChange={handleChangeDepartments}
                    options={
                      departmentOptions?.length
                        ? departmentOptions?.map(
                            ({
                              organizationUnitID,
                              departmentName,
                            }: IDepartment) => ({
                              id: organizationUnitID,
                              val: departmentName,
                            })
                          )
                        : []
                    }
                    disabled={!departmentOptions?.length || !inspectionsAccount}
                    multiple
                    id="inspections-filters-departments"
                  />
                </FilterWrapper>
                <RadioGroupWrapper>
                  <Label>{`${t("has-comments")}?`}</Label>
                  <RadioGroup
                    value={hasComments || ""}
                    onChange={handleChangeHasComments}
                  >
                    <FormControlLabel
                      value={HAS_VALUES_OPTIONS.ALL}
                      control={<Radio icon={<CheckedIcon />} />}
                      label={t("all")}
                    />
                    <FormControlLabel
                      value={HAS_VALUES_OPTIONS.HAS}
                      control={<Radio icon={<CheckedIcon />} />}
                      label={t("yes")}
                    />
                    <FormControlLabel
                      value={HAS_VALUES_OPTIONS.HAS_NOT}
                      control={<Radio icon={<CheckedIcon />} />}
                      label={t("no")}
                    />
                  </RadioGroup>
                </RadioGroupWrapper>
              </FiltersWrapper>
              <FiltersWrapper>
                <FilterWrapper>
                  <Label>{t("pi")}</Label>
                  <Autocomplete
                    value={contacts || null}
                    onChange={handleChangeContacts}
                    options={
                      contactsOptions?.length
                        ? getContactOptions(contactsOptions)
                        : []
                    }
                    disabled={!contactsOptions?.length || !inspectionsAccount}
                    multiple
                    id="inspections-filters-contacts"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("date-from")}</Label>
                  <DatePicker
                    value={startDate}
                    onChange={handleChangeStartDate}
                    maxDate={endDate}
                    id="inspections-filters-date-from"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("date-to")}</Label>
                  <DatePicker
                    value={endDate}
                    onChange={handleChangeEndDate}
                    minDate={startDate}
                    id="inspections-filters-date-to"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("follow-up")}</Label>
                  <Autocomplete
                    value={followUp || null}
                    onChange={handleChangeFollowUp}
                    options={
                      followUpOptions?.length
                        ? getFollowUpOptions(followUpOptions)
                        : []
                    }
                    disabled={!followUpOptions?.length}
                    multiple
                    id="inspections-filters-follow-up"
                  />
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("score")}</Label>
                  <ScoreWrapper>
                    <Autocomplete
                      value={scoreComparison || null}
                      onChange={handleChangeScoreComparison}
                      options={scoreComparisonOptions}
                      id="inspections-filters-score-comparison"
                    />
                    <Input
                      value={score || ""}
                      type="number"
                      onChange={handleChangeScore}
                      id="inspections-filters-score"
                    />
                  </ScoreWrapper>
                </FilterWrapper>
                <FilterWrapper>
                  <Label>{t("inspection-type")}</Label>
                  <Autocomplete
                    options={
                      inspectionTypeOptions?.length
                        ? getInspectionsTypeOptions(inspectionTypeOptions)
                        : []
                    }
                    value={inspectionType || null}
                    onChange={handleChangeInspectionType}
                    disabled={!inspectionTypeOptions?.length}
                    id="inspections-filters-inspection-type"
                  />
                </FilterWrapper>
                <RadioGroupWrapper>
                  <Label>{`${t("has-attachments")}?`}</Label>
                  <RadioGroup
                    value={hasAttachments || ""}
                    onChange={handleChangeHasAttachments}
                  >
                    <FormControlLabel
                      value={HAS_VALUES_OPTIONS.ALL}
                      control={<Radio icon={<CheckedIcon />} />}
                      label={t("all")}
                    />
                    <FormControlLabel
                      value={HAS_VALUES_OPTIONS.HAS}
                      control={<Radio icon={<CheckedIcon />} />}
                      label={t("yes")}
                    />
                    <FormControlLabel
                      value={HAS_VALUES_OPTIONS.HAS_NOT}
                      control={<Radio icon={<CheckedIcon />} />}
                      label={t("no")}
                    />
                  </RadioGroup>
                </RadioGroupWrapper>
              </FiltersWrapper>
            </FiltersContainer>
          )}
          <ActionsWrapper>
            <Button
              isGrey
              text={t("clear-filters")}
              onClick={handleClearFilters}
              id="inspections-filters-clear-filters"
            />
            <Button
              isGrey
              text={t("cancel")}
              onClick={handleFilters}
              id="inspections-filters-cancel"
            />
            <Button
              text={t("apply")}
              onClick={handleApplyFilters}
              id="inspections-filters-apply"
            />
          </ActionsWrapper>
        </>
      )}
    </InspectionsFiltersContainer>
  );
};
