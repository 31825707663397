import {
  GroupCellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import {
  getLocaleDateValue,
  getSortedByMonthFormat,
} from "../manifestTable/constants";
import { DescriptionContainer } from "./styles";

export const SYSTEM_MESSAGES_GRID_NAME = "system-messages";

export const rowGroupPanelShow = "always";

export const defaultColDef = {
  resizable: true,
};

const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnDefs = (t: (value: string) => string) => [
  {
    headerName: t("title"),
    field: "title",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("description"),
    field: "description",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
    cellRenderer: ({ data }: GroupCellRendererParams) =>
      data ? (
        <DescriptionContainer
          dangerouslySetInnerHTML={{ __html: data?.description }}
        />
      ) : null,
  },
  {
    headerName: t("start-displaying"),
    field: "startDisplayDate",
    ...excelMode,
    flex: 1,
    sortable: true,
    useValueFormatterForExport: true,
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.startDisplayDate);
    },
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.startDisplayDate);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
  },
  {
    headerName: t("stop-displaying"),
    field: "stopDisplayDate",
    ...excelMode,
    flex: 1,
    sortable: true,
    useValueFormatterForExport: true,
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.stopDisplayDate);
    },
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.stopDisplayDate);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
  },
];

export const SYSTEM_MESSAGES_STORAGE_KEYS = {
  SELECTED_SYSTEM_MESSAGES_ROW: "SELECTED_SYSTEM_MESSAGES_ROW",
  SELECTED_SYSTEM_MESSAGES: "selected_system-messages",
  SCROLL_ROW: "SELECTED_SYSTEM_MESSAGES_SCROLL_ROW",
};
