import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CellClickedEvent, SelectionChangedEvent } from "ag-grid-community";
import { AgGrid } from "src/shared/components/agGrid/agGrid";
import { useGetAllInspectionTemplatesByUserAccountQuery } from "src/shared/store/api/inspection.api";
import { IManageInspectionQuestionFields } from "src/components/manageInspectionQuestion/types";
import { STATUS_ID_VALUES } from "src/pages/newProfile/constants";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "src/shared/helpers/localStorage";
import { useHistory } from "src/shared/hooks/history/historyProvider";
import { useAppActions } from "src/shared/hooks/redux/redux";
import {
  getColumnDefs,
  defaultColDef,
  rowGroupPanelShow,
  INSPECTIONS_NOTIFICATIONS_GRID_NAME,
  INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS,
} from "./constants";

export const InspectionsNotificationsTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { history } = useHistory();

  const [selectedInspectionNotification, setSelectedInspectionNotification] =
    useState<IManageInspectionQuestionFields>();

  const previousLocation = history?.[history.length - 2] as string;
  const previousInspectionNotificationView =
    previousLocation &&
    previousLocation?.includes("inspection-notification") &&
    !previousLocation?.includes("inspections-notifications");
  const {
    setInspectionNotificationFilterScroll,
    setInspectionNotificationFiltersSelectedRow,
  } = useAppActions();

  const scrollToTop = getFromLocalStorage(
    INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS.SCROLL_ROW
  );

  const {
    data: manageInspections,
    isLoading: isLoadingInspectionsNotifications,
    isFetching: isFetchingInspectionsNotifications,
  } = useGetAllInspectionTemplatesByUserAccountQuery({
    statusId: STATUS_ID_VALUES.PUBLISH,
  });

  const columnDefs = getColumnDefs(t);

  const onCellClicked = (params: CellClickedEvent) => {
    setInspectionNotificationFilterScroll(window.scrollY);
    setInspectionNotificationFiltersSelectedRow(params?.data);
    if (
      params?.data?.inspectionID ===
      selectedInspectionNotification?.inspectionID
    ) {
      navigate(`/inspection-notification/${params?.data?.inspectionID}`);
    }
  };

  const onSelectionChanged = useCallback((props: SelectionChangedEvent) => {
    const selectedRows = props?.api?.getSelectedRows();
    setSelectedInspectionNotification(selectedRows[0]);
  }, []);

  const modifiedManageInspections = useMemo(
    () =>
      manageInspections?.map((inspection: IManageInspectionQuestionFields) => ({
        id: inspection?.inspectionID,
        ...inspection,
      })),
    [manageInspections]
  );

  useEffect(() => {
    if (typeof scrollToTop === "number" && previousInspectionNotificationView) {
      window.scroll(0, scrollToTop);
    }
  }, [scrollToTop, manageInspections, previousInspectionNotificationView]);

  useEffect(() => {
    if (previousInspectionNotificationView) {
      saveToLocalStorage(
        INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS.SELECTED_INSPECTIONS_NOTIFICATIONS,
        getFromLocalStorage(
          INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS.SELECTED_INSPECTIONS_NOTIFICATIONS_ROW
        )
      );
    } else {
      saveToLocalStorage(
        INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS.SELECTED_INSPECTIONS_NOTIFICATIONS,
        {}
      );
    }
  }, [previousInspectionNotificationView]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveToLocalStorage(
        INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS.SELECTED_INSPECTIONS_NOTIFICATIONS,
        null
      );
      saveToLocalStorage(
        INSPECTIONS_NOTIFICATIONS_STORAGE_KEYS.SELECTED_INSPECTIONS_NOTIFICATIONS_ROW,
        null
      );
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <AgGrid
      title={t("inspections-notifications")}
      gridName={INSPECTIONS_NOTIFICATIONS_GRID_NAME}
      rowData={modifiedManageInspections}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      noRowsText={t("no-inspections-notifications-display")}
      rowGroupPanelShow={rowGroupPanelShow}
      rowSelection={"single"}
      enableRangeSelection={true}
      onCellClicked={onCellClicked}
      onSelectionChanged={onSelectionChanged}
      isLoadingData={
        isLoadingInspectionsNotifications || isFetchingInspectionsNotifications
      }
      withSavingSearch={!!previousInspectionNotificationView}
      withSavingPage={!!previousInspectionNotificationView}
    />
  );
};
