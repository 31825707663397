import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";
import { Dialog } from "src/shared/components/dialog/dialog";
import { Button } from "src/shared/components/button/button";
import { Select } from "src/shared/components/select/select";
import { ISelectShippingNameDialog } from "./types";
import {
  DialogBody,
  SelectShippingNameDialogContentContainer,
  SendBtnWrapper,
} from "./styles";

export const SelectShippingNameDialog = ({
  shippingNameOptions,
  handleSelectShippingName,
  open,
  handleClose,
}: ISelectShippingNameDialog) => {
  const { t } = useTranslation();
  const [shippingName, setShippingName] = useState<string>("");

  const selectShippingNameDialogActions = () => {
    return (
      <SendBtnWrapper>
        <Button
          isGrey
          text={t("cancel")}
          onClick={handleClose}
          id="select-shipping-name-cancel"
        />
        <Button
          text={t("continue")}
          disabled={!shippingName}
          onClick={() => {
            handleSelectShippingName(shippingName);
            handleClose();
          }}
          id="select-shipping-name-continue"
        />
      </SendBtnWrapper>
    );
  };

  const handleChangeShippingName = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value;
    setShippingName(value as unknown as string);
  };

  const renderSelectShippingNameDialogFields = () => {
    return (
      <SelectShippingNameDialogContentContainer>
        <DialogBody>
          {t("select-applicable-name")}
          <Select
            value={shippingName}
            options={shippingNameOptions}
            onChange={handleChangeShippingName}
            id="select-shipping-name"
          />
        </DialogBody>
      </SelectShippingNameDialogContentContainer>
    );
  };

  useEffect(() => {
    setShippingName("");
  }, [open]);

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t("shipping-name")}
      actions={selectShippingNameDialogActions}
      renderFields={renderSelectShippingNameDialogFields}
    />
  );
};
