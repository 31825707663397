import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { CircularProgress } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormCard } from "src/shared/components/formCard/formCard";
import { Divider, FieldName } from "src/shared/components/formCard/styles";
import { Autocomplete } from "src/shared/components/autocomplete/autocomplete";
import { Button } from "src/shared/components/button/button";
import { Input } from "src/shared/components/input/input";
import {
  useGetAccountOrganizationUnitIDByRoomIDQuery,
  useGetContactsByAccountOrganizationUnitIDQuery,
  useGetDepartmentsByAccountOrganizationUnitIDQuery,
  useGetGeneratedBarcodeQuery,
  useGetLocationByIdAndDocTypeQuery,
  useGetLocationTypesQuery,
  useLazyGeneratedPdfWithBarcodeByLocationIDQuery,
  useUpsertLocationMutation,
} from "src/shared/store/api/organization.api";
import { useAppActions, useAppSelector } from "src/shared/hooks/redux/redux";
import { IAutocompleteOption } from "src/shared/components/autocomplete/types";
import { IOption } from "src/shared/components/select/types";
import { PrintQrCodesButton } from "../printQrCodesButton/printQrCodesButton";
import { DeleteLocationDialog } from "../deleteLocationDialog/deleteLocationDialog";
import { EditLocationParametersValidationSchema } from "./model";
import { IEditLocationFields, IEditLocationParameters } from "./types";
import {
  ADVISE_LOCATION,
  EditLocationFieldsWithoutIds,
  getContactOptions,
  getDepartmentOptions,
  getLocationTypesOptions,
} from "./constants";
import {
  FieldWrapper,
  EditLocationParametersContainer,
  ButtonsWrapper,
  FieldInputWrapper,
  FieldsWrapper,
  IndicatesRequiredField,
} from "./styles";

export const EditLocationParameters = ({ isEdit }: IEditLocationParameters) => {
  const { t } = useTranslation();
  const { locationID, roomID, docType } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EditLocationParametersValidationSchema(t)),
    mode: "onSubmit",
  });

  const [selectedType, setSelectedType] = useState<IAutocompleteOption>();
  const [department, setDepartment] = useState<IAutocompleteOption>();
  const [contact, setContact] = useState<IAutocompleteOption>();
  const [isOpenDeleteLocationDialog, setIsOpenDeleteLocationDialog] =
    useState<boolean>(false);

  const changedData = useAppSelector(
    (state) => state.unsavedChangesReducer.changedData
  );

  const {
    setChangedData,
    setIsOpenUnsavedChangedDialog,
    setHandleBackUnsavedChanged,
    setHandleUpdateUnsavedChanged,
  } = useAppActions();

  const { data: generatedBarcode } = useGetGeneratedBarcodeQuery(
    {},
    { skip: isEdit }
  );

  const [
    updateLocation,
    { isSuccess: isSuccessUpsertLocation, isLoading: isLoadingUpsertLocation },
  ] = useUpsertLocationMutation();

  const { data: location, isLoading: isLoadingLocation } =
    useGetLocationByIdAndDocTypeQuery(
      { locationID, docType },
      {
        skip: !locationID,
      }
    );

  const { data: accountId } = useGetAccountOrganizationUnitIDByRoomIDQuery(
    roomID ? roomID : location?.parentID,
    {
      skip: !roomID && !location?.parentID,
    }
  );

  const { data: locationTypes } = useGetLocationTypesQuery({});

  const handleCancel = useCallback(() => {
    if (location?.parentID || roomID) {
      navigate(`/room-information/${roomID ? roomID : location?.parentID}`);
    }
  }, [location?.parentID, navigate, roomID]);

  const backToRoom = () => {
    if (changedData) {
      setIsOpenUnsavedChangedDialog(true);
    } else {
      handleCancel();
    }
  };

  const { data: departments } =
    useGetDepartmentsByAccountOrganizationUnitIDQuery(accountId, {
      skip: !accountId,
    });

  const { data: contacts } = useGetContactsByAccountOrganizationUnitIDQuery(
    accountId,
    { skip: !accountId }
  );

  const [
    generatePdfWithBarcode,
    { isFetching: isFetchingGeneratePdfWithBarcode },
  ] = useLazyGeneratedPdfWithBarcodeByLocationIDQuery({});

  const locationTypesOptions = getLocationTypesOptions(locationTypes);
  const departmentOptions = getDepartmentOptions(departments);
  const contactsOptions = getContactOptions(contacts);

  useEffect(() => {
    if (isEdit) {
      EditLocationFieldsWithoutIds.forEach((field: string) => {
        setValue(field as IEditLocationFields, location?.[field]);
      });
      setSelectedType({
        id: location?.locationTypeID,
        val: location?.locationType,
      });
      setDepartment({
        id: location?.departmentID,
        val: location?.departmentName,
      });
      setContact({
        id: location?.contactID,
        val: location?.piContactFullName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleChange =
    (name: IEditLocationFields, id: IEditLocationFields) =>
    (_: SyntheticEvent<Element, Event>, newInputValue: unknown) => {
      const inputValue = newInputValue as IOption;

      setChangedData(true);

      switch (name) {
        case "locationType":
          setSelectedType(inputValue);
          break;
        case "departmentName":
          setDepartment(inputValue);
          break;
        case "piContactFullName":
          setContact(inputValue);
          break;
      }

      setValue(name, inputValue?.val, {
        shouldValidate: true,
      });
      setValue(id, inputValue?.id, {
        shouldValidate: true,
      });
    };

  useEffect(() => {
    if (location?.parentID || roomID) {
      setValue("parentId", location ? location?.parentID : roomID);
      setValue("id", location?.id);
    }
  }, [location, roomID, setValue]);

  const handleUpdateLocation = handleSubmit((data) => {
    updateLocation(data);
  });

  const handleChangedData = () => {
    setChangedData(true);
  };

  const handleCloseDeleteLocationDialog = () => {
    setIsOpenDeleteLocationDialog(false);
  };

  const handleDeleteLocation = () => {
    setIsOpenDeleteLocationDialog(true);
  };

  const handlePrintQrCodes = () => {
    generatePdfWithBarcode(locationID);
  };

  useEffect(() => {
    setHandleBackUnsavedChanged(() => {
      handleCancel();
    });
  }, [handleCancel, setHandleBackUnsavedChanged]);

  useEffect(() => {
    setHandleUpdateUnsavedChanged(() => {
      handleUpdateLocation();
    });
  }, [handleUpdateLocation, setHandleUpdateUnsavedChanged]);

  useEffect(() => {
    return () => {
      setChangedData(false);
    };
  }, [setChangedData]);

  useEffect(() => {
    if (isSuccessUpsertLocation) {
      setChangedData(false);
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpsertLocation]);

  useEffect(() => {
    if (generatedBarcode) {
      setValue("barcode", generatedBarcode);
    }
  }, [generatedBarcode, setValue]);

  return (
    <BlockUi
      tag="div"
      blocking={
        isLoadingLocation ||
        isLoadingUpsertLocation ||
        isFetchingGeneratePdfWithBarcode
      }
      loader={<CircularProgress />}
      keepInView
    >
      <EditLocationParametersContainer>
        <FormCard title={t("location")}>
          <FieldsWrapper>
            <IndicatesRequiredField>
              <span>*</span> {t("indicates-a-required-field")}
            </IndicatesRequiredField>
            <FieldInputWrapper>
              <FieldName>
                {t("name")}
                <span>*</span>
              </FieldName>
              <Input
                errorMessage={errors?.name?.message}
                register={{
                  ...register("name", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isEdit && location?.doc_type !== ADVISE_LOCATION}
                id="edit-location-parameters-name"
              />
            </FieldInputWrapper>
            <Divider />
            <FieldWrapper>
              <FieldName>
                {t("type")}
                <span>*</span>
              </FieldName>
              <Autocomplete
                value={selectedType || null}
                options={locationTypesOptions}
                onChange={handleChange("locationType", "locationTypeID")}
                errorMessage={errors?.locationType?.message}
                disabled={isEdit && location?.doc_type !== ADVISE_LOCATION}
                id="edit-location-parameters-type"
              />
            </FieldWrapper>
            <FieldInputWrapper>
              <FieldName>{t("barcode")}</FieldName>
              <Input
                errorMessage={errors?.barcode?.message}
                register={{
                  ...register("barcode", {
                    onChange: handleChangedData,
                  }),
                }}
                disabled={isEdit && location?.doc_type !== ADVISE_LOCATION}
                id="edit-location-parameters-barcode"
              />
            </FieldInputWrapper>
            <FieldWrapper>
              <FieldName>{t("department")}</FieldName>
              <Autocomplete
                value={department || null}
                options={departmentOptions}
                onChange={handleChange("departmentName", "departmentID")}
                errorMessage={errors?.departmentName?.message}
                disabled={isEdit && location?.doc_type !== ADVISE_LOCATION}
                id="edit-location-parameters-department"
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldName>{t("pi")}</FieldName>
              <Autocomplete
                value={contact || null}
                options={contactsOptions}
                onChange={handleChange("piContactFullName", "contactID")}
                errorMessage={errors?.piContactFullName?.message}
                disabled={isEdit && location?.doc_type !== ADVISE_LOCATION}
                id="edit-location-parameters-contact"
              />
            </FieldWrapper>
          </FieldsWrapper>
        </FormCard>
        <ButtonsWrapper>
          {isEdit && location?.barcode && (
            <PrintQrCodesButton handlePrintQrCodes={handlePrintQrCodes} />
          )}
          {isEdit && (
            <Button
              text={t("delete")}
              onClick={handleDeleteLocation}
              isError
              id="edit-location-parameters-delete"
            />
          )}
          <Button
            text={t("cancel")}
            onClick={backToRoom}
            isGrey
            id="edit-location-parameters-cancel"
          />
          {((isEdit && location?.doc_type === ADVISE_LOCATION) || !isEdit) && (
            <Button
              text={t("save")}
              onClick={handleUpdateLocation}
              id="edit-location-parameters-save"
            />
          )}
        </ButtonsWrapper>
      </EditLocationParametersContainer>
      <DeleteLocationDialog
        locationId={locationID}
        open={isOpenDeleteLocationDialog}
        handleClose={handleCloseDeleteLocationDialog}
        successCallBack={handleCancel}
      />
    </BlockUi>
  );
};
