import { createTheme, PaletteMode, responsiveFontSizes } from "@mui/material";
import { DARK, LIGHT } from "../hooks/theme/theme";

export const getDesignTokens = (mode: PaletteMode) => {
  const theme = responsiveFontSizes(
    createTheme({
      palette: {
        mode,
        ...(mode === LIGHT
          ? {
              // palette values for light mode
              primary: {
                main: "#25895C",
              },
              secondary: {
                main: "#282828",
              },
              background: {
                default: "#FFF",
              },
              text: {
                primary: "#25895C",
                secondary: "#282828",
              },
              error: {
                main: "#E35D2B",
              },
            }
          : {
              // palette values for dark mode
              primary: {
                main: "#202124",
              },
              secondary: {
                main: "#FFF",
              },
              background: {
                default: "#35363A",
              },
              text: {
                primary: "#FFF",
                secondary: "#FFF",
              },
              error: {
                main: "#E35D2B",
              },
            }),
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 360,
          md: 768,
          lg: 1200,
          xl: 1440,
        },
      },
      components: {},
    })
  );

  theme.components = {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: "absolute",
          top: 42,
          marginTop: 2,
          fontFamily: "Roboto",
          fontSize: 12,
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          [theme.breakpoints.down("md")]: {
            fontSize: 10,
            top: 28,
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          paddingTop: "0 !important",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          ...(mode === LIGHT
            ? {
                padding: "6px 8px",
                borderRadius: "8px",
                border: "1px solid #B1B1B1",
                background: "#FFF",
                color: "#282828",
                fontFamily: "Roboto",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }
            : {
                padding: "6px 8px",
                borderRadius: 8,
                border: "0.4px solid #D9D9D9",
                background: "#2A2C2F",
                color: "#FFF",
                fontFamily: "Roboto",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }),
        },
        popupIndicator: {
          padding: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        nativeInput: {
          height: "100%",
        },
        icon: {
          top: "auto",
          [theme.breakpoints.down("lg")]: {
            width: 16,
            height: 16,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          border: "1px solid #B1B1B1",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          backgroundColor: "#E35D2B",
          color: "#FFF",
          "& > div": {
            color: "#FFF !important",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
        },
        paper: {
          ...(mode === LIGHT
            ? {
                padding: "6px 8px",
                borderRadius: "8px",
                border: "1px solid #B1B1B1",
                background: "#FFF",
                color: "#282828",
                fontFamily: "Roboto",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                width: 180,
                maxWidth: 180,
                minWidth: 180,
              }
            : {
                padding: "6px 8px",
                borderRadius: 8,
                border: "0.4px solid #D9D9D9",
                background: "#2A2C2F",
                color: "#FFF",
                fontFamily: "Roboto",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                width: 180,
                minWidth: 180,
              }),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "0 4px",
          marginTop: 4,
          minHeight: "auto",
          "&:hover": {
            background:
              mode === DARK
                ? "rgba(37, 137, 92, 0.5)"
                : "rgba(37, 137, 92, 0.20)",
          },
          "&::first-of-type": {
            marginTop: 0,
          },
          "&.Mui-selected": {
            background:
              mode === DARK
                ? "rgba(88, 196, 147, 0.08)"
                : "rgba(33, 123, 219, 0.15)",
            "&:hover": {
              background:
                mode === DARK
                  ? "rgba(37, 137, 92, 0.5)"
                  : "rgba(37, 137, 92, 0.20)",
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: "Roboto",
        },
        h2: {
          fontFamily: "Roboto",
        },
        h3: {
          fontFamily: "Roboto",
        },
        h4: {
          fontFamily: "Roboto",
        },
        h5: {
          fontFamily: "Roboto",
        },
        h6: {
          fontFamily: "Roboto",
        },
        body1: {
          fontFamily: "Roboto",
        },
        body2: {
          fontFamily: "Roboto",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        li: {
          listStylePosition: "inside",
        },
        "*, *::before, *::after": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
          letterSpacing: "initial",
        },
        ".block-ui-container": {
          zIndex: 200,
        },
        ".ag-panel, .ag-default-panel, .ag-dialog, .ag-ltr, .ag-popup-child, .ag-focus-managed":
          {
            top: "0 !important",
          },
        ".ag-panel-title-bar": {
          background: mode === DARK ? "#35363A" : "#FFF",
        },
        ".ag-column-drop-horizontal": {
          border: "none",
        },
        ".ag-header, .ag-advanced-filter-header": {
          border: "none",
        },
        ".ag-cell": {
          display: "flex",
          alignItems: "center",
        },
        ".ag-row": {
          "&:nth-of-type(2n)": {
            background: "rgba(88, 196, 147, 0.05);",
          },
        },
        ".ag-header-cell, .ag-cell, .ag-column-drop-list": {
          fontFamily: "Roboto",
        },
        ".ag-root-wrapper": {
          borderRadius: 10,
          border: "none",
        },
        ".ag-cell-focus, .ag-cell": {
          border: "none !important",
        },
        ".ag-theme-alpine": {
          "--ag-range-selection-background-color": "transparent",
          "--ag-foreground-color": mode === DARK ? "#FFF" : "#282828",
          "--ag-background-color": mode === DARK ? "#35363A" : "#FFF",
          "--ag-header-foreground-color": "#25895C",
          "--ag-header-background-color": "transparent",
          "--ag-odd-row-background-color": "transparent",
          "--ag-header-column-resize-handle-color":
            mode === DARK ? "#FFF" : "#282828",
          "--ag-border-color": "#25895C",
          "--ag-control-panel-background-color":
            mode === DARK ? "#35363A" : "#FFF",
          "--ag-disabled-foreground-color": mode === DARK ? "#FFF" : "#282828",
          "--ag-modal-overlay-background-color": "transparent",
          [theme.breakpoints.down("md")]: {
            "--ag-font-size": "12px",
          },

          "--ag-font-size": "15px",
          "--ag-font-family:": "Roboto",
        },
        ".ag-paging-row-summary-panel": {
          [theme.breakpoints.down("md")]: {
            margin: "0 5px",
          },
        },
        ".ag-paging-panel": {
          border: "none",
        },
        body: {
          margin: 0,
          overflowX: "hidden",
        },
        a: {
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: mode === DARK ? "#58C493" : "#25895C",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 4,
          borderRadius: 2,
          backgroundColor: mode === DARK ? "#58C493" : "#25895C",
          width: 4,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          top: 40,
          minWidth: 258,
          border: mode === DARK ? "" : "1px solid #D9D9D9",
          background: mode === DARK ? "#292A2D" : "#F5F5F5",
          padding: "32px 16px",
        },
      },
    },
  };

  return theme;
};
