import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material";
import { PAGINATION_PAGE_SIZE_OPTIONS } from "src/shared/components/agGrid/customPagination/constants";
import { CustomPagination } from "src/components/sitesList/sitesBody/customPagination/customPagination";
import { FormCardCheckbox } from "src/shared/components/formCard/formCardCheckbox/formCardCheckbox";
import { ISite } from "src/pages/accountInformation/types";
import { ISiteSelection } from "./types";
import {
  SiteSelectionWrapper,
  SiteSelectionName,
  SiteSelectionNameWrapper,
} from "./styles";

export const SiteSelection = ({
  siteItems,
  keyword,
  currentPage,
  setCurrentPage,
  paginationPageSize,
  setPaginationPageSize,
  selectedSiteList,
  setSelectedSiteList,
}: ISiteSelection) => {
  const { t } = useTranslation();

  const paginationPageNumberSize = Number(
    PAGINATION_PAGE_SIZE_OPTIONS?.find((el) => el?.id === paginationPageSize)
      ?.val
  );

  const filteredItems = siteItems?.filter(
    (item: ISite) =>
      !!item?.name?.toLowerCase().includes(keyword?.toLowerCase())
  );

  const totalPages = Math.ceil(
    filteredItems?.length / paginationPageNumberSize
  );

  const handleChangePaginationPageSize = (e: SelectChangeEvent<unknown>) => {
    e.stopPropagation();
    e.preventDefault();
    setPaginationPageSize(e?.target?.value as string);
    setCurrentPage(0);
  };

  const handleChangeSites = (organizationUnitID: number) => () => {
    const isSelectedId = selectedSiteList?.includes(organizationUnitID);

    if (isSelectedId) {
      const newAccounts = selectedSiteList?.filter(
        (id: unknown) => id !== organizationUnitID
      );
      setSelectedSiteList(newAccounts as number[]);
    } else {
      const newAccounts = [...selectedSiteList, organizationUnitID];
      setSelectedSiteList(newAccounts as number[]);
    }
  };

  const selectAllSites = useMemo(
    () =>
      filteredItems?.every(({ organizationUnitID }: ISite) =>
        selectedSiteList.includes(organizationUnitID as unknown as number)
      ),
    [selectedSiteList, filteredItems]
  );

  const filteredItemIds = filteredItems?.map(
    ({ organizationUnitID }: ISite) => organizationUnitID
  );

  const handleSelectAllSites = () => {
    if (selectAllSites) {
      const newSites = selectedSiteList?.filter((id: unknown) => {
        return !filteredItemIds?.includes(id as string);
      });

      setSelectedSiteList(newSites as number[]);
    } else {
      const newSites = [...selectedSiteList, ...filteredItemIds];
      const sitesSet = new Set(newSites);
      const sitesArray = Array.from(sitesSet);

      setSelectedSiteList(sitesArray as number[]);
    }
  };

  return (
    <div>
      <SiteSelectionWrapper>
        <SiteSelectionNameWrapper>
          <FormCardCheckbox
            checked={!!selectAllSites}
            onChange={handleSelectAllSites}
            id="site-selection-all"
          />
          <SiteSelectionName>{t("all-sites")}</SiteSelectionName>
        </SiteSelectionNameWrapper>
      </SiteSelectionWrapper>
      {filteredItems
        ?.slice(
          currentPage * paginationPageNumberSize,
          (currentPage + 1) * paginationPageNumberSize
        )
        ?.map(
          (
            { name, organizationUnitID, generatorStatusID }: ISite,
            index: number
          ) => (
            <React.Fragment key={organizationUnitID}>
              <SiteSelectionWrapper>
                <SiteSelectionNameWrapper>
                  <FormCardCheckbox
                    checked={
                      !!selectedSiteList.find(
                        (id: unknown) => id === organizationUnitID
                      )
                    }
                    onChange={handleChangeSites(
                      organizationUnitID as unknown as number
                    )}
                    id={`site-selection-${organizationUnitID}`}
                  />
                  <SiteSelectionName>{name}</SiteSelectionName>
                </SiteSelectionNameWrapper>
                <SiteSelectionName>
                  {generatorStatusID || "-"}
                </SiteSelectionName>
              </SiteSelectionWrapper>
              {index !== siteItems?.length - 1}
            </React.Fragment>
          )
        )}
      <CustomPagination
        totalPages={totalPages}
        currentPage={currentPage}
        paginationPageSize={paginationPageSize}
        setCurrentPage={setCurrentPage}
        handleChangePaginationPageSize={handleChangePaginationPageSize}
      />
    </div>
  );
};
