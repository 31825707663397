import { GetContextMenuItemsParams } from "ag-grid-community";

export const MANAGE_INSPECTIONS_GRID_NAME = "manage-inspections";

export const rowGroupPanelShow = "always";

export const defaultColDef = {
  resizable: true,
};

const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnDefs = (t: (value: string) => string) => [
  {
    headerName: t("inspection-title"),
    field: "title",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("account"),
    field: "accountName",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("inspection-type"),
    field: "storageType",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("schedule"),
    field: "schedule",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
    hide: true,
  },
  {
    headerName: t("status"),
    field: "status",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
];

export const MANAGE_INSPECTIONS_STORAGE_KEYS = {
  SELECTED_MANAGE_INSPECTIONS_ROW: "SELECTED_MANAGE_INSPECTIONS_ROW",
  SELECTED_MANAGE_INSPECTIONS: "selected_manage-inspections",
  SCROLL_ROW: "SELECTED_MANAGE_SCROLL_ROW",
};

export const getContextMenuItems =
  (
    t: (value: string) => string,
    setManageInspectionId: (value: string) => void,
    setOpenCopyManageInspectionDialog: (value: boolean) => void
  ) =>
  (params: GetContextMenuItemsParams) =>
    [
      {
        name: t("copy-inspection"),
        action: () => {
          setManageInspectionId(params?.node?.data?.inspectionID);
          setOpenCopyManageInspectionDialog(true);
        },
        icon: '<span class="ag-icon ag-icon-copy" />',
      },
      "cut",
      "copy",
      "copyWithHeaders",
      "copyWithGroupHeaders",
      "paste",
      "separator",
      "export",
    ];
