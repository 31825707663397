import { Box, Typography, styled } from "@mui/material";

export const DialogBody = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    minWidth: "fit-content",
    fontSize: 14,
  },
}));

export const SelectShippingNameDialogContentContainer = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: 500,
    gap: 24,
    [theme.breakpoints.down("md")]: {
      gap: 12,
      width: "auto",
    },
  })
);

export const SendBtnWrapper = styled(Box)(({ theme }) => ({
  width: 270,
  display: "flex",
  gap: 10,
  "& > button": {
    height: 35,
    "&:first-of-type": {
      border: "1px solid #282828",
    },
    [theme.breakpoints.down("md")]: {
      height: 28,
    },
  },
}));
