export const HAZARDOUS_GRID_NAME = "hazardous";
export const NON_HAZARDOUS_GRID_NAME = "non-hazardous";

export const paginationPageSize = 25;

export const defaultColDef = {
  resizable: true,
};

const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnDefsHazardous = (t: (value: string) => string) => [
  {
    headerName: t("name"),
    field: "hazardousMaterials",
    ...excelMode,
    flex: 1,
    sortable: true,
  },
  {
    headerName: t("un-number"),
    field: "identificationNumbers",
    ...excelMode,
    flex: 1,
    sortable: true,
  },
  {
    headerName: t("p-g"),
    field: "packingGroup",
    ...excelMode,
    flex: 1,
    sortable: true,
  },
  {
    headerName: t("haz-code"),
    field: "labelCodes",
    ...excelMode,
    flex: 1,
    sortable: true,
  },
  {
    headerName: t("special-provisions"),
    field: "specialProvisions",
    ...excelMode,
    flex: 1,
    sortable: true,
    hide: true,
  },
  {
    headerName: t("version"),
    field: "version",
    ...excelMode,
    flex: 1,
    sortable: true,
    hide: true,
  },
];

export const getColumnDefsNonHazardous = (t: (value: string) => string) => [
  {
    headerName: t("shipping-name"),
    field: "shippingName",
    ...excelMode,
    flex: 1,
    sortable: true,
  },
];

export const TIME_FRAME_OPTIONS = [
  { id: "Weekly", val: "Weekly" },
  { id: "Monthly", val: "Monthly" },
  { id: "Quarterly", val: "Quarterly" },
  { id: "Annually", val: "Annually" },
];

export const DOT_INFORMATION_KEYS = [
  "commonShippingName",
  "fullShippingName",
  "lineConstituents",
  "containerSize",
  "containerWeightUom",
  "containerType",
  "nonDotShippingName_id",
  "hazardousMaterial_id",
  "anticipatedQuantity",
  "anticipatedSize",
  "anticipatedTimeframe",
];
