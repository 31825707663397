import * as yup from "yup";

export const InspectionNotificationFormValidationSchema = (
  t: (value: string) => string
) =>
  yup.object().shape({
    notificationSchedule: yup
      .array()
      .of(
        yup.object().shape({
          settingID: yup.number().required(t("required-field")),
          value: yup.boolean().required(t("required-field")),
        })
      )
      .min(1, t("required-field")),
    emailDistributionList: yup
      .array()
      .of(yup.string())
      .required(t("required-field"))
      .min(1, t("required-field")),
  });
