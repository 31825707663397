import { ColDef, ValueFormatterParams } from "ag-grid-community";
import { QUESTION_TYPE_ID } from "src/components/inspectionsTable/inspectionInformation/inspectionQuestions/constants";

export const INSPECTION_QUESTIONS_GRID_NAME = "inspection-questions";

export const paginationPageSize = 25;

export const defaultColDef = {
  sortable: true,
  resizable: true,
};

const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnDefs = (
  hasEdit: boolean,
  t: (value: string) => string
) => {
  const columns = [
    {
      headerName: "#",
      field: "questionNumber",
      ...excelMode,
      width: 50,
    },
    {
      headerName: t("prompt"),
      field: "prompt",
      ...excelMode,
      flex: 1,
    },
    {
      headerName: t("type"),
      field: "typeID",
      ...excelMode,
      width: 100,
    },
    {
      headerName: t("default-answer"),
      field: "defaultAnswer",
      valueFormatter: ({ data }: ValueFormatterParams) => {
        return data?.typeID === QUESTION_TYPE_ID.TRFL
          ? data?.defaultAnswerBool
          : data?.typeID === QUESTION_TYPE_ID.TEXT
          ? data?.defaultAnswerText
          : "";
      },
      ...excelMode,
      flex: 1,
      hide: true,
    },
    {
      headerName: t("answer-required"),
      field: "answerRequired",
      cellDataType: "text",
      ...excelMode,
      flex: 1,
      hide: true,
    },
    {
      headerName: t("findings-required"),
      field: "numberOfViolationsRequired",
      cellDataType: "text",
      ...excelMode,
      flex: 1,
      hide: true,
    },
  ] as ColDef[];

  if (hasEdit) {
    columns.unshift({
      cellRenderer: "agGroupCellRenderer",
      field: "",
      width: 50,
      suppressMovable: true,
      resizable: false,
      suppressMenu: true,
    } as ColDef);
  }

  return columns;
};

export const TYPE_OPTIONS = [
  { id: QUESTION_TYPE_ID.TRFL, val: "True/False" },
  { id: QUESTION_TYPE_ID.NMBR, val: "Number" },
  { id: QUESTION_TYPE_ID.TEXT, val: "Text" },
];
