import {
  GetContextMenuItemsParams,
  ValueFormatterParams,
} from "ag-grid-community";
import TrashContextMenu from "src/shared/assets/svgs/trash-context-menu.svg";
import TrashContextMenuWhite from "src/shared/assets/svgs/trash-context-menu-white.svg";
import {
  getLocaleDateValue,
  getSortedByMonthFormat,
} from "src/components/manifestTable/constants";
import { ISubscription } from "./types";

export const MANAGE_SCHEDULED_REPORTS_GRID_NAME = "manage-scheduled-reports";

export const rowGroupPanelShow = "always";

export const defaultColDef = {
  resizable: true,
};

const excelMode = {
  filter: "agSetColumnFilter",
  filterParams: {
    excelMode: true,
  },
};

export const getColumnDefs = (t: (value: string) => string) => [
  {
    headerName: t("schedule-name"),
    field: "descriptionField",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("report-type"),
    field: "reportField",
    ...excelMode,
    flex: 1,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("create-date"),
    field: "modifiedDateField",
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return getLocaleDateValue(data?.modifiedDateField);
    },
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    useValueFormatterForExport: true,
    ...excelMode,
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.modifiedDateField);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: t("last-run-date"),
    field: "lastExecutedField",
    valueFormatter: ({ data }: ValueFormatterParams) => {
      return data?.lastExecutedField !== "0001-01-01T00:00:00"
        ? getLocaleDateValue(data?.lastExecutedField)
        : "";
    },
    cellRendererParams: {
      innerRenderer: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    useValueFormatterForExport: true,
    ...excelMode,
    filterParams: {
      excelMode: true,
      valueGetter: ({ data }: ValueFormatterParams) => {
        return getSortedByMonthFormat(data?.lastExecutedField);
      },
      valueFormatter: (valueFormatterParams: ValueFormatterParams) => {
        return getLocaleDateValue(valueFormatterParams?.value);
      },
    },
    sortable: true,
    enableRowGroup: true,
  },
];

export const MANAGE_SCHEDULED_REPORTS_STORAGE_KEYS = {
  SELECTED_MANAGE_SCHEDULED_REPORTS_ROW:
    "SELECTED_MANAGE_SCHEDULED_REPORTS_ROW",
  SELECTED_MANAGE_SCHEDULED_REPORTS: "selected_manage-scheduled-reports",
  SCROLL_ROW: "SELECTED_MANAGE_SCHEDULED_REPORTS_SCROLL_ROW",
};

export const getContextMenuItems =
  (
    setDeleteSubscriptionSelected: (ScheduledReportID: ISubscription) => void,
    setOpenDeleteSubscriptionDialog: (status: boolean) => void,

    t: (value: string) => string,
    isDark: boolean
  ) =>
  (params: GetContextMenuItemsParams) =>
    [
      {
        name: t("delete"),
        action: () => {
          setDeleteSubscriptionSelected(params?.node?.data);
          setOpenDeleteSubscriptionDialog(true);
        },
        icon: `<img src="${
          isDark ? TrashContextMenuWhite : TrashContextMenu
        }"/>`,
      },
      "cut",
      "copy",
      "copyWithHeaders",
      "copyWithGroupHeaders",
      "paste",
      "separator",
      "export",
    ];
